import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormHeader } from '@/components/FormHeader';
import { ContinueWhereYouLeftOff } from '@/components/orderForm/continueWhereYouLeft';
import { useEffect } from 'react';
import { useStepNavigation } from '@/lib/utils/useStepNavigation';
import { Layout } from '@/components/ui/Layout';
import { FormBody } from '@/components/FormBody';

import { useGlobalStore } from '@/lib/store/global';
import { z } from 'zod';
import { childDetailsSchema } from '@/lib/schemas/childDetailsSchema';
import { useDataSubmission } from '@/lib/utils';
import { SubmitButton } from '@/components/SubmitButton';
import {
  Similarity,
  similarityOptionalSchema,
  similaritySchema,
} from '@/lib/schemas/similaritySchema';
import { useHeroImages } from '@/lib/hooks/useHeroImages';
import { FormField } from '@/components/FormField';
import { Textarea } from '@/components/ui/textarea';
import { Skeleton } from '@/components/ui/skeleton';

const CURRENT_STEP = 9;

const similarityPageSchema = childDetailsSchema.merge(similarityOptionalSchema);

export function SimilarityPage() {
  const { getFormData } = useGlobalStore();
  const data = getFormData(similarityPageSchema);
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);

  useEffect(() => {
    if (!data) {
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <SimilarityForm data={data} /> : null;
}

function SimilarityForm({
  data,
}: {
  data: z.infer<typeof similarityPageSchema>;
}) {
  const { saveFormData } = useGlobalStore();
  const {
    handleSubmit,
    watch,
    formState,
    register,
    control,
    formState: { errors },
  } = useForm<Similarity>({
    resolver: zodResolver(similaritySchema),
    defaultValues: data,
  });
  useEffect(() => watch(saveFormData).unsubscribe, [watch, saveFormData]);
  const { goToPreviousStep, goToNextStep } = useStepNavigation(CURRENT_STEP);
  const submitData = useDataSubmission(CURRENT_STEP);
  const { data: heroImages } = useHeroImages();
  const onSubmit = () => submitData().finally(goToNextStep);

  return (
    <Layout>
      <FormHeader
        title={`${data.hero_name}'s Bespoke Adventure`}
        currentStep={CURRENT_STEP}
        onBack={goToPreviousStep}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody>
          <div className="flex flex-col gap-4">
            <h2 className="text-2xl text-center">
              You know your hero the best
            </h2>
            <p className="text-xl text-muted-foreground">
              Help us create illustrations that truly reflect {data.hero_name}{' '}
              by choosing the one that most closely matches their likeness.
            </p>
            {heroImages ? (
              <>
                <div className="grid grid-cols-2 gap-2">
                  <Controller
                    name="hero_similarity_image_number"
                    control={control}
                    render={({ field }) => (
                      <>
                        {heroImages.hero_preview_image_urls_low_res.map(
                          (url, i) => (
                            <label key={i}>
                              <input
                                name="hero_similarity_image_number"
                                type="radio"
                                value={i + 1}
                                onChange={() => field.onChange(i + 1)}
                                className="hidden"
                              />
                              <img
                                src={url}
                                alt={`${data.hero_name} image ${i + 1}`}
                                className={`rounded-lg border-2 ${
                                  field.value === i + 1
                                    ? 'border-primary'
                                    : 'border-border opacity-70'
                                }`}
                              />
                            </label>
                          ),
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="bg-tertiary text-primary-foreground p-2 rounded-lg font-semibold flex justify-center items-center">
                  <p className="text-center">
                    Preview images—our artists will perfect the final
                    illustrations for {data.hero_name}’s book. 🧑‍🎨
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="grid grid-cols-2 gap-2">
                  <Skeleton className="aspect-square" />
                  <Skeleton className="aspect-square" />
                  <Skeleton className="aspect-square" />
                  <Skeleton className="aspect-square" />
                </div>
                <Skeleton className="h-10" />
              </>
            )}
          </div>
          <FormField
            label="Note"
            inputId="br_order_hero_similarity_note"
            description={`Have we missed anything? Let us know if there are any unique features of ${data.hero_name} that we haven't captured.`}
            optional
          >
            <Textarea
              {...register('hero_similarity_note')}
              id="br_order_hero_similarity_note"
              placeholder={`${data.hero_name}'s unique features`}
              isError={!!errors.hero_similarity_note}
            />
          </FormField>
          <SubmitButton />
        </FormBody>
      </form>
      {!formState.isSubmitting && !formState.isSubmitted && (
        <ContinueWhereYouLeftOff />
      )}
    </Layout>
  );
}
