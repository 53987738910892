import { useCallback, useState } from 'react';
import { Image } from 'lucide-react';
import { cn } from '@/lib/utils';

export function ImageUpload({
  onUpload,
  multiple,
  isError,
}: {
  onUpload: (files: FileList) => void;
  multiple: boolean;
  isError: boolean;
}) {
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const onDragOver = useCallback((e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    setIsDraggingOver(true);
  }, []);
  const onDragLeave = useCallback(() => {
    setIsDraggingOver(false);
  }, []);
  const onDrop = useCallback(
    (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault();
      setIsDraggingOver(false);
      if (e.dataTransfer?.files?.length) {
        onUpload(e.dataTransfer.files);
      }
    },
    [onUpload],
  );
  const onFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.target.files?.length) {
        onUpload(e.target.files);
      }
    },
    [onUpload],
  );

  return (
    <label
      className={cn(
        'flex flex-col items-center justify-center rounded-5 border-border border-2 hover:bg-background cursor-pointer w-full h-48',
        isDraggingOver && 'border-primary text-primary',
        isError && 'border-destructive',
      )}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <Image size="3rem" className="mb-2" />
      <p className="text-lg">Click to add photos</p>
      <p
        className={cn(
          'text-sm text-muted-foreground',
          isDraggingOver && 'text-primary',
        )}
      >
        Or drag and drop
      </p>
      <input
        type="file"
        accept=".png,.jpg,.jpeg,.avif,.webp,.heic"
        multiple={multiple}
        className="hidden"
        onChange={onFileInputChange}
      />
    </label>
  );
}
