import { useCallback, useEffect, useState } from 'react';
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from './ui/carousel';
import { boyImages, girlImages } from '@/lib/config/exampleImages';

export function Examples() {
  const [example, setExample] = useState<'girl' | 'boy'>('girl');
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [api, setApi] = useState<CarouselApi>();

  const onScroll = useCallback(() => {
    if (api) {
      setIsFirstSlide(api.slidesInView().includes(0));
    }
  }, [api]);

  useEffect(() => {
    if (!api) {
      return;
    }
    api
      .on('reInit', onScroll)
      .on('scroll', onScroll)
      .on('slideFocus', onScroll);
  }, [api, onScroll]);

  return (
    <div className="flex flex-col gap-3">
      <h2 className="w-full text-center text-2xl pb-1 mt-4 mb-4">
        What to expect?
      </h2>
      <p>
        Get a glimpse of other bespoke books we've crafted.
      </p>
      <div className="flex gap-2">
        <button
          type="button"
          className="flex w-1/2"
          onClick={() => {
            setExample('girl');
            api?.scrollTo(0);
          }}
        >
          <img
            src={girlImages[0]}
            alt="girl's book example"
            className={`rounded-lg border-2 ${example === 'girl' ? 'border-primary' : 'border-border'}`}
          />
        </button>
        <button
          type="button"
          className="flex w-1/2"
          onClick={() => {
            setExample('boy');
            api?.scrollTo(0);
          }}
        >
          <img
            src={boyImages[0]}
            alt="boy's book example"
            className={`rounded-lg border-2 ${example === 'boy' ? 'border-primary' : 'border-border'}`}
          />
        </button>
      </div>
      <Carousel setApi={setApi} className="select-none">
        <CarouselContent>
          {(example === 'girl' ? girlImages : boyImages).map((img, i) => (
            <CarouselItem key={i}>
              <img src={img} alt={`book example ${i}`} />
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious type="button" />
        <CarouselNext
          type="button"
          className={
            isFirstSlide ? 'text-transparent hover:text-transparent' : ''
          }
        />
      </Carousel>
    </div>
  );
}
