import { SUGGESTION_REQUEST_TIMEOUT } from '@/lib/config/suggestions';
import { ChildDetails } from '@/lib/schemas/childDetailsSchema';
import { Occasion } from '@/lib/schemas/occasionSchema';
import { withTimeout } from '@/lib/utils/withTimeout';

export type GetThemeSuggestionParams = ChildDetails & Occasion;

export const fetchThemeSuggestions = (
  order_reference_id: string,
  params: GetThemeSuggestionParams,
  previousSuggestions: string[],
) =>
  withTimeout(
    fetchFromApi(order_reference_id, params, previousSuggestions),
    SUGGESTION_REQUEST_TIMEOUT,
  );

async function fetchFromApi(
  order_reference_id: string,
  params: ChildDetails & Occasion,
  previousSuggestions: string[],
) {
  const url = new URL('/preview/order-assistant', import.meta.env.VITE_API_URL);
  url.searchParams.append('task', 'theme_suggestions');
  url.searchParams.append('order_reference_id', order_reference_id);

  const { book_occasion, ...rest } = params;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      country_iso2: 'uk',
      occasion: book_occasion,
      ...rest,
      previous_suggestions: previousSuggestions,
    }),
  });

  if (!response.ok) {
    throw new Error('Fetching theme suggestions failed');
  }

  const data = await response.json();
  return data.theme_suggestions as string[];
}
