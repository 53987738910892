import { FormHeader } from '@/components/FormHeader';
import { ContinueWhereYouLeftOff } from '@/components/orderForm/continueWhereYouLeft';
import {
  ChildDetails,
  childDetailsSchema,
} from '@/lib/schemas/childDetailsSchema';
import { useStepNavigation } from '@/lib/utils/useStepNavigation';
import { SubmitButton } from '@/components/SubmitButton';
import { useEffect, useState } from 'react';
import { Layout } from '@/components/ui/Layout';
import { FormBody } from '@/components/FormBody';
import { ImageStep } from '@/components/orderForm/imageStep';
import bannerImage from '@/assets/banners/6.avif';
import { useGlobalStore } from '@/lib/store/global';
import { useDataSubmission } from '@/lib/utils';
import { useStories } from '@/lib/hooks/useStories';

const CURRENT_STEP = 6;

export function PhotoPage() {
  const { getFormData } = useGlobalStore();
  const data = getFormData(childDetailsSchema);
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);

  useEffect(() => {
    if (!data) {
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <PhotoForm data={data} /> : null;
}

function PhotoForm({ data }: { data: ChildDetails }) {
  useStories(); // preload stories
  const { goToPreviousStep, goToNextStep } = useStepNavigation(CURRENT_STEP);
  const { firstImage, uploadingImages } = useGlobalStore();
  const [submitted, setSubmitted] = useState(false);
  const submitData = useDataSubmission(CURRENT_STEP);
  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!firstImage) {
      return;
    }
    try {
      await submitData();
    } finally {
      goToNextStep();
    }
  };

  return (
    <Layout>
      <FormHeader
        title={`${data.hero_name}'s Bespoke Adventure`}
        currentStep={CURRENT_STEP}
        onBack={uploadingImages.length > 0 ? undefined : goToPreviousStep}
      />
      <form onSubmit={onSubmit}>
        <FormBody>
          <div className="flex-1 lg:max-h-fit">
            <ImageStep
              label="Show Us the Star"
              description="Upload a clear, front-facing photo with no obstructions - just the star of the story!"
              bannerImage={bannerImage}
              imageCount={1}
              images={
                uploadingImages.length > 0
                  ? uploadingImages.map((i) => ({
                      presigned_url: i,
                      original_file_name: i,
                      s3_path: i,
                    }))
                  : firstImage
                    ? [firstImage]
                    : []
              }
              isError={submitted && !firstImage}
            />
          </div>
          <SubmitButton disabled={uploadingImages.length > 0} />
        </FormBody>
      </form>
      <ContinueWhereYouLeftOff />
    </Layout>
  );
}
