type FormFieldProps = {
  inputId?: string;
  label: string;
  description?: string;
  optional?: boolean;
  children: React.ReactNode;
};

export const FormField = ({
  inputId,
  label,
  description,
  optional,
  children,
}: FormFieldProps) => {
  return (
    <div className="py-8 flex flex-col items-start justify-start gap-3 w-full">
      <div className="flex flex-col gap-2">
        <label htmlFor={inputId} className="text-2xl">
          {label}
          {optional && (
            <span className="text-secondary-foreground font-light cursor-default">
              &nbsp;(optional)
            </span>
          )}
        </label>
        {description && (
          <p className="text-xl text-secondary-foreground font-light cursor-default">
            {description}
          </p>
        )}
      </div>
      {children}
    </div>
  );
};
