import { z } from 'zod';

const suggestionsSchema = z.array(z.string());

export function getStoredSuggestions(storageKey: string) {
  try {
    return suggestionsSchema.parse(
      JSON.parse(localStorage.getItem(storageKey) ?? ''),
    );
  } catch {
    return [];
  }
}
