import { SUGGESTION_REQUEST_TIMEOUT } from '@/lib/config/suggestions';
import { ChildDetails } from '@/lib/schemas/childDetailsSchema';
import { Occasion } from '@/lib/schemas/occasionSchema';
import { PersonalisationNote } from '@/lib/schemas/personalisationNoteSchema';
import { Theme } from '@/lib/schemas/themeSchema';
import { withTimeout } from '@/lib/utils/withTimeout';

export type GetDedicationMessageSuggestionParams = ChildDetails &
  Occasion &
  Theme &
  PersonalisationNote;

export const fetchDedicationMessageSuggestions = (
  order_reference_id: string,
  params: GetDedicationMessageSuggestionParams,
  previousSuggestions: string[],
) =>
  withTimeout(
    fetchFromApi(order_reference_id, params, previousSuggestions),
    SUGGESTION_REQUEST_TIMEOUT,
  );

async function fetchFromApi(
  order_reference_id: string,
  params: GetDedicationMessageSuggestionParams,
  previousSuggestions: string[],
) {
  const url = new URL('/preview/order-assistant', import.meta.env.VITE_API_URL);
  url.searchParams.append('task', 'dedication_suggestions');
  url.searchParams.append('order_reference_id', order_reference_id);

  const { book_occasion, book_theme, book_personalisation_note, ...rest } =
    params;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      country_iso2: 'uk',
      occasion: book_occasion,
      theme: book_theme ?? '',
      personalisation_note: book_personalisation_note ?? '',
      ...rest,
      previous_suggestions: previousSuggestions,
    }),
  });

  if (!response.ok) {
    throw new Error('Fetching dedication_message suggestions failed');
  }

  const data = await response.json();
  return data.dedication_suggestions as string[];
}
