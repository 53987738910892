import { getStoredSuggestions } from '../getStoredSuggestions';

export function removeStoredSuggestion(storageKey: string, suggestion: string) {
  localStorage.setItem(
    storageKey,
    JSON.stringify(
      getStoredSuggestions(storageKey).filter((s) => suggestion !== s),
    ),
  );
}
