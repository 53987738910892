import { useEffect } from 'react';
import { useMixpanel } from '@/lib/utils/index';

export function HandleDeviceId() {
  const handleDeviceId = useMixpanel();
  useEffect(() => {
    handleDeviceId();
  }, []);

  return null;
}
