import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormHeader } from '@/components/FormHeader';
import { ContinueWhereYouLeftOff } from '@/components/orderForm/continueWhereYouLeft';
import { useEffect } from 'react';
import { useStepNavigation } from '@/lib/utils/useStepNavigation';
import { Layout } from '@/components/ui/Layout';
import { FormBody } from '@/components/FormBody';
import { shippingSchema } from '@/lib/schemas/shippingSchema';

import { Check, Minus, Plus, Ship, BookOpenCheck } from 'lucide-react';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Cart, cartOptionalSchema, cartSchema } from '@/lib/schemas/cartSchema';
import {
  ADDITIONAL_BOOK_PRICE,
  AUDIO_BOOK_PRICE,
  FIRST_BOOK_PRICE,
  SECOND_BOOK_DISCOUNT_PERCENT,
} from '@/lib/config/prices';
import { useGlobalStore } from '@/lib/store/global';
import { z } from 'zod';
import { childDetailsSchema } from '@/lib/schemas/childDetailsSchema';
import { useDataSubmission } from '@/lib/utils';
import { Preview } from '@/components/Preview';
import { occasionSchema } from '@/lib/schemas/occasionSchema';
import { themeSchema } from '@/lib/schemas/themeSchema';
import { personalisationNoteSchema } from '@/lib/schemas/personalisationNoteSchema';
import { dedicationMessageSchema } from '@/lib/schemas/dedicationMessageSchema';
import { Examples } from '@/components/Examples';
import { StyleExamples } from '@/components/StyleExamples';
import { Reviews } from '@/components/Reviews';
import { useToast } from '@/components/ui/use-toast';

const CURRENT_STEP = 10;

const cartPageSchema = childDetailsSchema
  .merge(occasionSchema)
  .merge(themeSchema)
  .merge(personalisationNoteSchema)
  .merge(dedicationMessageSchema)
  .merge(shippingSchema)
  .merge(cartOptionalSchema);

export function CartPage() {
  const { getFormData } = useGlobalStore();
  const data = getFormData(cartPageSchema);
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);

  useEffect(() => {
    if (!data) {
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <CartForm data={data} /> : null;
}

function CartForm({ data }: { data: z.infer<typeof cartPageSchema> }) {
  const { saveFormData, order_reference_id } = useGlobalStore();
  const { handleSubmit, watch, setValue, control, formState } = useForm<Cart>({
    resolver: zodResolver(cartSchema),
    defaultValues: {
      book_quantity: data.book_quantity ?? 1,
      include_audio_book:
        'include_audio_book' in data ? Boolean(data.include_audio_book) : false,
      preview_before_shipping:
        'preview_before_shipping' in data ? data.preview_before_shipping : true,
    },
  });
  useEffect(() => watch(saveFormData).unsubscribe, [watch, saveFormData]);
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);
  const submitData = useDataSubmission(CURRENT_STEP);
  const { toast } = useToast();
  const onSubmit = async () => {
    if (import.meta.env.VITE_ENVIRONMENT !== 'production') {
      throw new Error('Aborting order confirmation in dev mode');
    }
    try {
      await submitData();
      redirectToPayment();
    } catch (error) {
      console.error(error);
      toast({
        variant: 'destructive',
        title: 'Failed to submit your response',
        description:
          'There was an issue submitting your response. Please try again later.',
      });
    }
  };
  const includeAudioBook = watch('include_audio_book');
  const bookQuantity = watch('book_quantity');
  const previewBeforeShipping = watch('preview_before_shipping');

  const currency = 'GBP';
  const currencySymbol = '£';

  const redirectToPayment = async () => {
    const url = new URL(
      '/stripe/stripe-payment-url',
      import.meta.env.VITE_API_URL,
    );
    const products = includeAudioBook ? ['book', 'audio'] : ['book'];
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        products: products,
        customer_email: data.user_email,
        client_reference_id: order_reference_id,
        language: 'en',
        testing: import.meta.env.VITE_ENVIRONMENT === 'development',
        book_quantity: bookQuantity,
        currency: currency.toLowerCase(),
      }),
    });

    if (!res.ok) {
      console.error('Failed to fetch payment url');
      return;
    }

    try {
      const { payment_url } = z
        .object({ payment_url: z.string() })
        .parse(await res.json());

      window.location.href = payment_url;
    } catch {
      console.error('Failed to parse payment url response');
    }
  };

  const getBookTotal = () => {
    const quantity = bookQuantity || 1;

    return FIRST_BOOK_PRICE + (quantity - 1) * ADDITIONAL_BOOK_PRICE;
  };

  const getTotal = () =>
    (
      (getBookTotal() + (includeAudioBook ? AUDIO_BOOK_PRICE : 0)) /
      100
    ).toFixed(2);

  return (
    <Layout>
      <FormHeader
        title={`${data.hero_name}'s Bespoke Adventure`}
        currentStep={CURRENT_STEP}
        onBack={goToPreviousStep}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody>
          <div className="flex-1 lg:max-h-fit">
            <div className="flex flex-col gap-5 w-full">
              <div className="flex flex-col gap-4 w-full overflow-auto">
                <h2 className="w-full text-center text-2xl pb-1">
                  Your Storybook Details
                </h2>
                <p>
                  Just one last step to gift {data.hero_name} a bespoke journey
                  that will be cherished for a lifetime.
                </p>
                <div className="flex flex-col gap-3 border bg-card p-3 rounded-lg">
                  <p>
                    This book will be designed to celebrate{' '}
                    <strong>{data.book_occasion}</strong>, making it a perfect
                    way to mark the event.
                  </p>
                  {data.book_theme && (
                    <p>
                      With <strong>{data.book_theme}</strong> as the backdrop,
                      your child's bespoke adventures will be brought to life
                      through stories inspired by this theme.
                    </p>
                  )}
                  {data.book_personalisation_note && (
                    <>
                      <p>
                        Your personal notes will help us add those unique
                        touches that make each story truly engaging.
                      </p>
                      <p>
                        <strong>{data.book_personalisation_note}</strong>
                      </p>
                    </>
                  )}
                  <p>
                    Your dedication will be a treasured part of the book, adding
                    a heartfelt personal touch.
                  </p>
                  <p>
                    <strong>{data.book_dedication_message}</strong>
                  </p>
                  <p>
                    {data.delivery_google_address && (
                      <>
                        <strong>
                          <i>{data.delivery_google_address}</i>
                        </strong>
                        &nbsp;-&nbsp;
                      </>
                    )}
                    We're getting your book ready for delivery, ensuring it
                    arrives at your door to delight and inspire.
                  </p>
                </div>
              </div>
              <Preview hero_name={data.hero_name} />
              <Examples />
              <StyleExamples />
              <div className="w-full text-center mt-6 mb-4">
                <CardTitle>Ready to Create Magic?</CardTitle>
              </div>
              <div className="flex flex-col gap-5 w-full">
                <div className="flex-1 w-full">
                  <ul className="flex flex-col gap-3">
                    <li className="flex flex-row gap-2 w-full">
                      <img
                        src="/icons/book-open.svg"
                        alt={`${data.hero_name}'s Book`}
                        className="w-8 h-8 object-cover rounded-lg"
                      />
                      <div className="flex flex-row items-center justify-between gap-2 flex-1">
                        <div className="flex flex-col gap-1">
                          <p className="text-lg">{data.hero_name}'s Book</p>
                          <i className="text-sm text-muted-foreground">
                            Add more identical premium books at{' '}
                            <strong>{SECOND_BOOK_DISCOUNT_PERCENT}%</strong> off
                            to gift others
                          </i>
                        </div>
                        <Controller
                          name="book_quantity"
                          control={control}
                          render={({ field }) => (
                            <div className="flex flex-row justify-center items-center gap-2">
                              <Button
                                type="button"
                                variant="outline"
                                size="icon"
                                className={field.value > 1 ? '' : 'invisible'}
                                onClick={() => {
                                  field.onChange(Math.max(1, field.value - 1));
                                }}
                              >
                                <Minus />
                              </Button>
                              <p className="w-5 text-center">
                                {field.value ?? 1}
                              </p>
                              <Button
                                type="button"
                                variant="outline"
                                size="icon"
                                onClick={() => {
                                  field.onChange((field.value ?? 1) + 1);
                                }}
                              >
                                <Plus />
                              </Button>
                            </div>
                          )}
                        />
                      </div>
                    </li>
                    <li className="flex flex-row gap-2">
                      <img
                        src="/icons/book-audio.svg"
                        alt={`${data.hero_name}'s Audiobook`}
                        className="w-8 h-8 object-cover rounded-lg"
                      />
                      <div className="flex flex-row items-center justify-between gap-2 flex-1">
                        <div className="flex flex-col gap-1">
                          <p className="text-lg">
                            {data.hero_name}'s Audiobook
                          </p>
                          <i className="text-sm text-muted-foreground">
                            Audio stories that you can listen with eyes closed
                          </i>
                        </div>
                        <Button
                          type="button"
                          size="icon"
                          className="min-w-10"
                          variant={includeAudioBook ? 'default' : 'outline'}
                          onClick={() => {
                            setValue('include_audio_book', !includeAudioBook);
                          }}
                        >
                          {includeAudioBook ? <Check /> : <Plus />}
                        </Button>
                      </div>
                    </li>
                    <li className="flex flex-row gap-2">
                      <BookOpenCheck size={32} color="black" />
                      <div className="flex flex-col flex-1">
                        <div className="flex justify-between">
                          <p className="text-lg">Preview Before Shipping</p>
                          <p className="leading-8 text-muted-foreground">
                            FREE
                          </p>
                        </div>
                        <div className="flex justify-between items-center gap-2">
                          <i className="text-sm text-muted-foreground mt-1">
                            Check to receive preview before printing, or uncheck
                            for a surprise
                          </i>
                          <Button
                            type="button"
                            size="icon"
                            className="min-w-10"
                            variant={
                              previewBeforeShipping ? 'default' : 'outline'
                            }
                            onClick={() => {
                              setValue(
                                'preview_before_shipping',
                                !previewBeforeShipping,
                              );
                            }}
                          >
                            {previewBeforeShipping ? <Check /> : <Plus />}
                          </Button>
                        </div>
                      </div>
                    </li>
                    <li className="flex flex-row gap-2">
                      <Ship size={32} color="black" />
                      <div className="flex flex-col flex-1">
                        <div className="flex justify-between">
                          <p className="text-lg">Express Delivery</p>
                          <p className="leading-8 text-muted-foreground">
                            FREE
                          </p>
                        </div>
                        <div className="flex justify-between items-center gap-2">
                          <i className="text-sm text-muted-foreground mt-1">
                            We'll ship the book in less than 10 days
                            {data.delivery_google_address && (
                              <>
                                &nbsp;to{' '}
                                <strong>{data.delivery_google_address}</strong>
                              </>
                            )}
                          </i>
                          <Button
                            type="button"
                            size="icon"
                            className="min-w-10"
                            disabled
                          >
                            <Check />
                          </Button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <Card className="h-fit w-full mx-auto">
                  <CardHeader>
                    <div className="text-sm text-muted-foreground">
                      <p className="my-2">
                        Complete your order to receive {data.hero_name}'s
                        bespoke book.
                      </p>
                      <ul className="list-none">
                        <li>
                          ✨ Truly bespoke storybook, featuring {data.hero_name}{' '}
                          exclusively in all stories
                        </li>
                        <li>
                          🎨 Hand-touched illustrations by professional artists
                        </li>
                        <li>
                          📚 Timeless keepsake - designed for {data.hero_name}{' '}
                          to be treasured for years
                        </li>
                        <li>
                          💖 Fosters lasting emotional bond and encourages a
                          love of reading
                        </li>
                      </ul>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <ul>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-muted-foreground">
                          {data.hero_name}'s Book
                          {bookQuantity > 1 ? ` (x${bookQuantity})` : ''}:
                        </p>
                        <p>
                          {currencySymbol} {(getBookTotal() / 100).toFixed(2)}
                        </p>
                      </div>
                      {includeAudioBook && (
                        <div className="flex flex-row justify-between items-center">
                          <p className="text-muted-foreground">
                            {data.hero_name}'s Audiobook:{' '}
                          </p>
                          <p>
                            {currencySymbol} {AUDIO_BOOK_PRICE / 100}
                          </p>
                        </div>
                      )}
                      {previewBeforeShipping && (
                        <div className="flex flex-row justify-between items-center">
                          <p className="text-muted-foreground">
                            Preview Before Shipping
                          </p>
                          <p>FREE</p>
                        </div>
                      )}
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-muted-foreground">
                          Express Delivery
                        </p>
                        <p>FREE</p>
                      </div>
                      <li className="flex flex-row justify-between items-center pt-3">
                        <p>Total:</p>
                        <p>
                          {currencySymbol} {getTotal()}
                        </p>
                      </li>
                    </ul>
                  </CardContent>
                  <CardFooter>
                    <Button type="submit" className="w-full">
                      Checkout
                    </Button>
                  </CardFooter>
                </Card>
              </div>
              <Reviews />
            </div>
          </div>
        </FormBody>
      </form>
      {!formState.isSubmitting && !formState.isSubmitted && (
        <ContinueWhereYouLeftOff />
      )}
    </Layout>
  );
}
