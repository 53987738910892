import { useGlobalStore } from '@/lib/store/global';
import { LAST_STEP, StepNumber } from '@/lib/config/steps';

function getStepEventName(step: StepNumber) {
  switch (step) {
    case 1:
      return 'Step 1: Hero Data';
    case 2:
      return 'Step 2: Occasion';
    case 3:
      return 'Step 3: Theme';
    case 4:
      return 'Step 4: Personalisation Note';
    case 5:
      return 'Step 5: Dedication Message';
    case 6:
      return 'Step 6: First Hero Photo';
    case 7:
      return 'Step 7: Additional Photos';
    case 8:
      return 'Step 8: Customer Details';
    case 9:
      return 'Step 9: Similarity Selection';
    case LAST_STEP:
      return 'Step 10: Overview';
  }
}

export const useDataSubmission = (stepNumber: StepNumber) => {
  const {
    deviceId,
    order_reference_id,
    formData,
    firstImage,
    additionalImages,
  } = useGlobalStore();

  return async (user_email?: string) => {
    const url = new URL(`/order-form-event`, import.meta.env.VITE_API_URL);
    url.searchParams.append('event_name', getStepEventName(stepNumber));
    url.searchParams.append('device_id', deviceId);
    url.searchParams.append('order_reference_id', order_reference_id);
    if (user_email) {
      url.searchParams.append('user_email', user_email);
    }
    const data = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [
        key,
        value === '' ? undefined : value,
      ]),
    );

    const res = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        book_language: 'en',
        image_1_url: firstImage?.s3_path ?? undefined,
        image_2_url: additionalImages[0]?.s3_path ?? undefined,
        image_3_url: additionalImages[1]?.s3_path ?? undefined,
        image_4_url: additionalImages[2]?.s3_path ?? undefined,
        image_5_url: additionalImages[3]?.s3_path ?? undefined,
        book_theme: formData.book_theme ?? '',
        ...data,
      }),
    });

    if (!res.ok) {
      throw new Error('Failed to submit form data');
    }
  };
};
