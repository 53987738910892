import { z } from 'zod';

export const deliveryCountrySchema = z.object({
  delivery_country: z.string().min(1),
});

export const shippingSchema = deliveryCountrySchema.extend({
  user_name: z.string().min(1),
  user_phone: z.string().min(1),
  user_email: z.string().min(1),
  delivery_city: z.string().min(1),
  delivery_postcode: z.string().min(2),
  delivery_address: z.string().min(1),
  delivery_google_address: z.string().nullable().optional(),
  delivery_house_number: z.string().min(1),
  delivery_address_2: z.union([
    z.string().min(2).optional(),
    z.literal('').transform(() => undefined),
  ]),
});

export type Shipping = z.infer<typeof shippingSchema>;

export const shippingOptionalSchema = shippingSchema.extend({
  user_name: z.string().optional(),
  user_phone: z.string().optional(),
  user_email: z.string().optional(),
  delivery_country: z.string().optional(),
  delivery_city: z.string().optional(),
  delivery_postcode: z.string().optional(),
  delivery_address: z.string().optional(),
  delivery_google_address: z.string().nullable().optional(),
  delivery_house_number: z.string().optional(),
  delivery_address_2: z.string().optional(),
});
export type ShippingOptional = z.infer<typeof shippingOptionalSchema>;
