import { X } from 'lucide-react';
import { Button } from './ui/button';

export function CloseFormButton() {
  return (
    <Button
      variant="ghost"
      size="icon"
      onClick={() => {
        window.location.href = 'https://blossomreads.com/';
      }}
    >
      <X />
    </Button>
  );
}
