import { rotateSuggestions } from '@/lib/utils/suggestions/rotateSuggestions';

const suggestions = [
  'Dream big, always.',
  'For my little hero.',
  'To my shining star.',
  'Adventure awaits you.',

  'With love, always from Dad and Mom.',
  'Keep exploring, keep dreaming, keep smiling.',
  'You are our greatest adventure, always.',
  'To our brave, kind-hearted little one.',

  'May your imagination take you to magical places.',
  'From Grandma and Grandpa, with all our love.',
  "For the world's best explorer, from Mom and Dad.",
  'Never stop dreaming, believing, and being yourself.',

  'To our little star, may you always shine bright and true.',
  'For our adventurous spirit, from Nanny and Grandad with all our love.',
  'You make the world a brighter place, keep shining, keep smiling.',
  'May this story inspire you to chase all your dreams, always.',
];

const generator = rotateSuggestions(
  [...suggestions].sort(() => Math.random() - 0.5),
);

export const getDedicationMessageSuggestionFallback = () =>
  generator.next().value!;
