import { z } from 'zod';

export const similaritySchema = z.object({
  hero_similarity_note: z.string().optional(),
  hero_similarity_image_number: z
    .literal(1)
    .or(z.literal(2))
    .or(z.literal(3))
    .or(z.literal(4)),
});
export type Similarity = z.infer<typeof similaritySchema>;

export const similarityOptionalSchema = similaritySchema.extend({
  hero_similarity_image_number:
    similaritySchema.shape.hero_similarity_image_number.optional(),
});
