import { Button, ButtonProps } from './ui/button';

export function SubmitButton(props: Pick<ButtonProps, 'disabled'>) {
  return (
    <Button
      type="submit"
      rounded
      className="max-sm:w-full sm:w-fit px-10 text-xs font-semibold mt-5 uppercase bg-tertiary hover:bg-tertiary-hover text-popover tracking-[2px]"
      {...props}
    >
      Continue
    </Button>
  );
}
