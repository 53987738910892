import { LAST_STEP, StepNumber } from '@/lib/config/steps';
import { useNavigate } from 'react-router-dom';

export function useStepNavigation(currentStep: StepNumber) {
  const navigate = useNavigate();
  return {
    goToPreviousStep: () =>
      navigate(getStepRoute(Math.max(0, currentStep - 1) as StepNumber)),
    goToNextStep: () =>
      navigate(
        getStepRoute(Math.min(LAST_STEP, currentStep + 1) as StepNumber),
      ),
  };
}

function getStepRoute(step: StepNumber) {
  switch (step) {
    case 1:
      return '/child-details';
    case 2:
      return '/occasion';
    case 3:
      return '/theme';
    case 4:
      return '/note';
    case 5:
      return '/message';
    case 6:
      return '/photo';
    case 7:
      return '/more-photos';
    case 8:
      return '/shipping';
    case 9:
      return '/similarity';
    case LAST_STEP:
      return '/cart';
  }
}
