import * as React from 'react';

import { cn } from '@/lib/utils';

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  isError?: boolean;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, isError, ...props }, ref) => {
    const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);

    function adjustHeight() {
      if (textareaRef.current) {
        textareaRef.current.style.height = '';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }

    React.useEffect(adjustHeight, [textareaRef.current?.value]);

    React.useEffect(() => {
      window.addEventListener('resize', adjustHeight);
      return () => window.removeEventListener('resize', adjustHeight);
    }, []);

    return (
      <textarea
        className={cn(
          'flex h-10 w-full ring-offset-background placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 overflow-hidden resize-none border-b-2 bg-transparent border-input pb-2 text-2xl transition-colors focus-visible:border-accent-foreground focus-visible:outline-none',
          isError && 'border-destructive',
          className,
        )}
        ref={(node) => {
          textareaRef.current = node;
          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            ref.current = node;
          }
        }}
        {...props}
      />
    );
  },
);
Textarea.displayName = 'Textarea';

export { Textarea };
