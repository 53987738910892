import { getStoredSuggestions } from '../getStoredSuggestions';

export function addStoredSuggestions(
  storageKey: string,
  suggestions: string[],
) {
  localStorage.setItem(
    storageKey,
    JSON.stringify([
      ...new Set([...getStoredSuggestions(storageKey), ...suggestions]),
    ]),
  );
}
