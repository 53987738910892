import { z } from 'zod';

export const cartSchema = z.object({
  include_audio_book: z.boolean(),
  book_quantity: z.number(),
  preview_before_shipping: z.boolean().optional(),
});
export type Cart = z.infer<typeof cartSchema>;

export const cartOptionalSchema = z.object({
  include_audio_book: z.boolean().optional(),
  book_quantity: z.number().optional(),
  preview_before_shipping: z.boolean().optional(),
});
export type CartOptional = z.infer<typeof cartOptionalSchema>;
