import img1 from '@/assets/reviews/1.avif';
import img2 from '@/assets/reviews/2.avif';
import google from '@/assets/reviews/google.png';

const reviews = [
  {
    image: img1,
    title: 'Cindy, 05/03/2024.',
    text: "Reading these stories and looking at the illustrations brought tears of joy to our eyes! I couldn't believe it is possible to see Anna's resemblance in pictures so clearly. This was a perfect 5th birthday gift. Thank you for your work!",
  },
  {
    image: img2,
    title: 'Susan, 15/06/2024.',
    text: "When I placed the order, I hoped to receive something special, but.... The result is simply fantastic! I can't imagine a more beautiful gift to give to my loved ones! Thank you for such a careful, personal, and beautifully crafted result!",
  },
];

export function Reviews() {
  return (
    <section className="flex flex-col gap-3">
      {reviews.map((review, i) => (
        <div
          className="flex sm:flex-row gap-2 h-full bg-card p-2 pr-3 rounded-lg border"
          key={i}
        >
          <img
            className="w-1/3 sm:w-1/5 object-contain rounded-sm self-start m-1"
            src={review.image}
            alt="reviewer"
          />
          <div className="flex flex-col sm:gap-1">
            <p>{review.title}</p>
            <p>⭐⭐⭐⭐⭐</p>
            <p className="italic text-sm sm:text-base">"{review.text}"</p>
          </div>
        </div>
      ))}
      <div className="flex gap-1 justify-center items-center">
        <p className="text-muted-foreground">Rated 5/5 ⭐⭐⭐⭐⭐ on</p>
        <img src={google} className="w-16 mt-[2px]" alt="google" />
      </div>
    </section>
  );
}
