import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { useGlobalStore } from '../store/global';
import { useStories } from './useStories';
import { useHeroImages } from './useHeroImages';
import { similaritySchema } from '../schemas/similaritySchema';

const defaultPreviewImage = '/images/book-preview/cover.jpg';
const MAX_FETCH_ATTEMPTS = 5;

type GetStoryImageParams = {
  mj_job_id: string;
  image_num: 1 | 2 | 3 | 4;
  story_title: string;
  story_text: string;
};

const storyImageSchema = z.object({
  final_image_url: z.string().min(1),
});

export const useStoryImage = () => {
  const { order_reference_id, getFormData } = useGlobalStore();
  const { data: stories } = useStories();
  const { data: heroImages } = useHeroImages();
  const data = getFormData(similaritySchema);
  const params: GetStoryImageParams | undefined =
    data && stories && heroImages
      ? {
          mj_job_id: heroImages.mj_job_id,
          image_num: data.hero_similarity_image_number,
          story_title: stories.title_1,
          story_text: stories.story_1,
        }
      : undefined;
  const { data: started } = useStoryImageJob(order_reference_id, params);

  return useQuery({
    queryKey: ['storyImage', order_reference_id, params],
    queryFn: () => getStoryImage(order_reference_id, params!),
    enabled: params && started,
  });
};

const useStoryImageJob = (
  order_reference_id: string,
  params?: GetStoryImageParams,
) =>
  useQuery({
    queryKey: ['storyImageJob', order_reference_id, params],
    queryFn: () => startStoryImageGeneration(order_reference_id, params!),
    enabled: !!params,
  });

async function startStoryImageGeneration(
  order_reference_id: string,
  params: GetStoryImageParams,
) {
  const url = new URL('/preview/order-assistant', import.meta.env.VITE_API_URL);
  url.searchParams.append('task', 'story_image');
  url.searchParams.append('order_reference_id', order_reference_id);

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  if (!response.ok) {
    throw new Error('Failed to start story image generation');
  }

  return (
    z.object({ status: z.string() }).parse(await response.json()).status ===
    'success'
  );
}

async function getStoryImage(
  order_reference_id: string,
  params: GetStoryImageParams,
  attempt = 1,
) {
  const url = new URL(
    '/preview/order-assistant/get-results',
    import.meta.env.VITE_API_URL,
  );
  url.searchParams.append('task', 'story_image');
  url.searchParams.append('order_reference_id', order_reference_id);

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  try {
    if (!response.ok) {
      throw new Error('Failed to fetch story image');
    }

    return storyImageSchema.parse(await response.json()).final_image_url;
  } catch {
    if (attempt > MAX_FETCH_ATTEMPTS) {
      return defaultPreviewImage;
    }
    return getStoryImage(order_reference_id, params, attempt + 1);
  }
}
