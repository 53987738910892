import { useCallback } from 'react';
import { Trash2, LoaderCircle } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Label } from '../ui/label';
import { Banner } from '@/components/Banner';
import { uploadImages } from '@/lib/utils/orderForm/uploadImages';
import { useGlobalStore } from '@/lib/store/global';
import { UploadedImage } from '@/lib/schemas/uploadedImageSchema';
import { ImageUpload } from '../ImageUpload';
import { preloadImage } from '@/lib/utils/preloadImage';

const getPreviewUrls = (files: FileList) =>
  Array.from(files).map(URL.createObjectURL);

export const ImageStep = ({
  label,
  description,
  imageCount,
  bannerImage,
  images,
  isError,
}: {
  label: string;
  description: string;
  imageCount: 1 | 4;
  images: UploadedImage[];
  bannerImage?: string;
  isError: boolean;
}) => {
  const {
    setFirstImage,
    addAdditionalImages,
    removeFirstImage,
    removeAdditionalImage,
    setUploadingImages,
    order_reference_id,
  } = useGlobalStore();

  const onUpload = useCallback(
    (files: FileList) => {
      setUploadingImages(getPreviewUrls(files));
      uploadImages(order_reference_id, files)
        .then(async (images) => {
          try {
            await Promise.all(images.map((i) => preloadImage(i.presigned_url)));
          } catch {
            // ignore preload errors
          }
          if (imageCount === 1) {
            setFirstImage(images[0]);
          } else {
            addAdditionalImages(images);
          }
        })
        .finally(() => setUploadingImages([]));
    },
    [
      setFirstImage,
      addAdditionalImages,
      imageCount,
      order_reference_id,
      setUploadingImages,
    ],
  );

  return (
    <div className="flex flex-col space-y-4 relative">
      <div className="relative mx-auto">
        {bannerImage && <Banner src={bannerImage} />}
        {imageCount === 1 && images[0] ? (
          <img
            src={images[0].presigned_url}
            className="absolute bottom-[28.4%] left-[42.6%] w-[9.5%] h-[13.8%] object-cover rounded-sm "
          />
        ) : null}
      </div>
      <div
        className={cn(
          'w-fit mx-auto flex flex-col items-start justify-start gap-4',
        )}
      >
        <Label htmlFor="image-upload" className="text-2xl w-full">
          {label}
        </Label>
        <p className="text-xl text-muted-foreground w-full">{description}</p>
        {images.length < imageCount && (
          <ImageUpload
            onUpload={onUpload}
            multiple={imageCount > 1}
            isError={isError}
          />
        )}
        <ImageList
          images={images}
          onRemove={imageCount === 1 ? removeFirstImage : removeAdditionalImage}
        />
        <p className="text-sm text-gray-500 w-full">
          🔒 Photos will be immediately deleted from our servers after
          illustrations are ready.
        </p>
      </div>
    </div>
  );
};

const ImageList = ({
  images,
  onRemove,
}: {
  images: UploadedImage[];
  onRemove: (image: UploadedImage) => void;
}) => (
  <div className="flex flex-col gap-4 py-4 w-full">
    {images.map((image) => (
      <div key={image.s3_path} className="flex justify-between items-center">
        <div
          className={cn(
            'max-w-20 aspect-square',
            'relative overflow-hidden w-full',
          )}
        >
          <div className="relative aspect-square">
            <img
              src={image.presigned_url}
              alt="hero"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
        <div className="flex items-center">
          {image.s3_path === image.presigned_url && (
            <LoaderCircle className="animate-spin" />
          )}
          <div
            className="cursor-pointer p-4 mr-2"
            onClick={() => onRemove(image)}
          >
            <Trash2 />
          </div>
        </div>
      </div>
    ))}
  </div>
);
