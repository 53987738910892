import { useEffect, useState } from 'react';
import { ChildDetails } from '../../../schemas/childDetailsSchema';
import { getOccasionSuggestions } from './getOccasionSuggestions';
import { useGlobalStore } from '@/lib/store/global';

export function useOccasionSuggestions(params: ChildDetails) {
  const { order_reference_id } = useGlobalStore();
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    getOccasionSuggestions(order_reference_id, params)
      .then(setSuggestions)
      .finally(() => {
        setLoading(false);
      });
  }, [order_reference_id, params]);

  return { suggestions, loading };
}
