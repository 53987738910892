import bannerImage from '@/assets/banners/7.avif';
import { Input } from '@/components/ui/input';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormHeader } from '@/components/FormHeader';
import { Banner } from '@/components/Banner';
import { ContinueWhereYouLeftOff } from '@/components/orderForm/continueWhereYouLeft';
import {
  ChildDetails,
  childDetailsSchema,
} from '@/lib/schemas/childDetailsSchema';
import { useEffect } from 'react';
import { useStepNavigation } from '@/lib/utils/useStepNavigation';
import { SubmitButton } from '@/components/SubmitButton';
import { Layout } from '@/components/ui/Layout';
import { FormBody } from '@/components/FormBody';
import {
  Shipping,
  ShippingOptional,
  shippingOptionalSchema,
  shippingSchema,
} from '@/lib/schemas/shippingSchema';
import { useParams } from 'react-router-dom';
import { countries } from '@/lib/utils/orderForm/countries';
import { CountrySelect } from '@/components/CountrySelect';
import { PhoneNumberInput } from '@/components/PhoneNumberInput';
import { useDataSubmission } from '@/lib/utils';
import { useGlobalStore } from '@/lib/store/global';
import { FormField } from '@/components/FormField';
import { AddressInput, AddressSuggestion } from '@/components/AddressInput';
import { useHeroImages } from '@/lib/hooks/useHeroImages';
import { preloadImage } from '@/lib/utils/preloadImage';

const CURRENT_STEP = 8;

export function ShippingPage() {
  const { getFormData } = useGlobalStore();
  const data = getFormData(childDetailsSchema.merge(shippingOptionalSchema));
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);

  useEffect(() => {
    if (!data) {
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <ShippingForm data={data} /> : null;
}

function ShippingForm({ data }: { data: ChildDetails & ShippingOptional }) {
  const { data: heroImages } = useHeroImages(); // preload hero images

  const { lang } = useParams();

  const { saveFormData } = useGlobalStore();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<Shipping>({
    resolver: zodResolver(shippingSchema),
    defaultValues: data,
  });
  useEffect(() => watch(saveFormData).unsubscribe, [watch, saveFormData]);
  useEffect(() => {
    if (lang) {
      const country = countries.find(
        (c) => c.key === lang.split('-')[1].toLowerCase(),
      );
      if (country) {
        setValue('delivery_country', country.value);
        setValue('user_phone', country.prefix);
      }
    }
  }, []);
  useEffect(() => {
    if (heroImages) {
      // preload hero images
      heroImages.hero_preview_image_urls_low_res.forEach(preloadImage);
    }
  }, [heroImages]);
  const country = watch('delivery_country');
  const { goToPreviousStep, goToNextStep } = useStepNavigation(CURRENT_STEP);
  const submitData = useDataSubmission(CURRENT_STEP);
  const onSubmit = (data: Shipping) =>
    submitData(data.user_email).finally(goToNextStep);

  function acceptSuggestion(address: AddressSuggestion) {
    setValue('delivery_google_address', address.formattedAddress);
    if (address.route) {
      setValue('delivery_address', address.route);
    }
    if (address.streetNumber) {
      setValue('delivery_house_number', address.streetNumber);
    }
    if (address.postalTown) {
      setValue('delivery_city', address.postalTown);
    } else if (address.locality) {
      setValue('delivery_city', address.locality);
    }
    if (address.postcode) {
      setValue('delivery_postcode', address.postcode);
    }
  }

  return (
    <Layout>
      <FormHeader
        title={`${data.hero_name}'s Bespoke Adventure`}
        currentStep={CURRENT_STEP}
        onBack={goToPreviousStep}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody>
          <div className="flex-1 lg:max-h-fit">
            <div className="h-fit">
              <div className="relative mx-auto">
                <Banner src={bannerImage} />
              </div>
              <Controller
                name="delivery_country"
                control={control}
                render={({ field }) => (
                  <CountrySelect
                    label="Shipping Country"
                    onChange={field.onChange}
                    value={field.value}
                    isError={!!errors.delivery_country}
                  />
                )}
              />
              <FormField inputId="br_order_user_name" label="Your Full Name">
                <Input
                  {...register('user_name')}
                  id="br_order_user_name"
                  placeholder="Ex. Margaret Thompson"
                  required
                  isError={!!errors.user_name}
                />
              </FormField>
              <Controller
                name="user_phone"
                control={control}
                render={({ field }) => (
                  <PhoneNumberInput
                    label="Phone Number"
                    onChange={field.onChange}
                    value={field.value}
                    isError={!!errors.user_phone}
                  />
                )}
              />
              <FormField inputId="br_order_user_email" label="Email Address">
                <Input
                  {...register('user_email')}
                  id="br_order_user_email"
                  placeholder="Ex. margaret.thompson@gmail.com"
                  required
                  isError={!!errors.user_name}
                />
              </FormField>
              <FormField
                inputId="br_order_delivery_address"
                label="Street Address"
              >
                <Controller
                  name="delivery_address"
                  control={control}
                  render={({ field }) => (
                    <AddressInput
                      id="br_order_delivery_address"
                      value={field.value}
                      onChange={field.onChange}
                      onAcceptSuggestion={acceptSuggestion}
                      country={country}
                      placeholder="Ex. High Street"
                      required
                      isError={!!errors.delivery_address}
                    />
                  )}
                />
              </FormField>
              <FormField
                inputId="br_order_delivery_house_number"
                label="House Number"
              >
                <Input
                  {...register('delivery_house_number')}
                  id="br_order_delivery_house_number"
                  placeholder="Ex. 22"
                  required
                  isError={!!errors.delivery_house_number}
                />
              </FormField>
              <FormField
                inputId="br_order_delivery_address_2"
                label="Address line 2"
                optional
              >
                <Input
                  {...register('delivery_address_2')}
                  id="br_order_delivery_address_2"
                  placeholder="Ex. Flat 3B"
                  isError={!!errors.delivery_address_2}
                />
              </FormField>
              <FormField inputId="br_order_delivery_postcode" label="Postcode">
                <Input
                  {...register('delivery_postcode')}
                  id="br_order_delivery_postcode"
                  placeholder="Ex. SW1A 1AA"
                  required
                  isError={!!errors.delivery_postcode}
                />
              </FormField>
              <FormField inputId="br_order_delivery_city" label="City">
                <Input
                  {...register('delivery_city')}
                  id="br_order_delivery_city"
                  placeholder="Ex. London"
                  required
                  isError={!!errors.delivery_city}
                />
              </FormField>
            </div>
          </div>
          <SubmitButton />
        </FormBody>
      </form>
      <ContinueWhereYouLeftOff />
    </Layout>
  );
}
