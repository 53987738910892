import { Button } from './ui/button';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';
import { Check, ChevronsUpDown } from 'lucide-react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './ui/command';

import 'intl-tel-input/styles';
import { useMemo, useState } from 'react';
import { countries } from '@/lib/utils/orderForm/countries';
import { cn } from '@/lib/utils';

type PhoneNumberInputProps = {
  label: string;
  value?: string;
  onChange: (value: string) => void;
  isError?: boolean;
};

export function PhoneNumberInput({
  label,
  value,
  onChange,
  isError,
}: PhoneNumberInputProps) {
  const [open, setOpen] = useState(false);
  const parsedValue = useMemo(() => {
    const [prefix, number = ''] = (value ?? '').split(' ');
    return { prefix, number };
  }, [value]);
  const selectedCountry = useMemo(
    () => countries.find((v) => v.prefix === parsedValue.prefix),
    [parsedValue.prefix],
  );

  return (
    <div className="flex flex-col py-8 gap-3 w-full">
      <Label htmlFor="br_order_user_phone_toggle" className="text-2xl">
        {label}
      </Label>
      <div className="flex flex-row gap-2 items-center w-full">
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              role="combobox"
              aria-expanded={open}
              className="w-[100px] justify-between"
              id="br_order_user_phone_toggle"
            >
              {selectedCountry
                ? `${selectedCountry.flag} ${selectedCountry.prefix}`
                : 'prefix'}
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent align="start" className="w-[200px] p-0">
            <Command filter={filterCountryPrefixes}>
              <CommandInput placeholder="Search prefix..." />
              <CommandEmpty>No prefix found.</CommandEmpty>
              <CommandList>
                <CommandGroup>
                  {countries.map((country) => (
                    <CommandItem
                      key={country.key}
                      value={country.prefix}
                      onSelect={(val) => {
                        onChange(`${val} ${parsedValue.number}`.trim());
                        setOpen(false);
                      }}
                    >
                      <Check
                        className={cn(
                          'mr-2 h-4 w-4',
                          selectedCountry?.prefix === country.prefix
                            ? 'opacity-100'
                            : 'opacity-0',
                        )}
                      />
                      {country.flag} {country.prefix}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
        <Input
          id="br_order_user_phone"
          name="user_phone_number"
          placeholder={(selectedCountry || countries[32]).placeholder}
          onChange={(e) =>
            onChange(
              `${parsedValue.prefix} ${e.target.value.replace(/\D/g, '')}`.trim(),
            )
          }
          required
          isError={isError}
          className="flex-1"
          value={parsedValue.number}
        />
      </div>
    </div>
  );
}

const filterCountryPrefixes = (value: string, search: string) => {
  const query = search.toLowerCase();
  if (value.startsWith(query)) return 1;
  if (value.startsWith(`+${query}`)) return 0.9;
  if (value.includes(query)) return 0.8;

  const country = countries.find((c) => c.prefix === value);
  if (!country) return 0;
  if (country.value.toLowerCase().startsWith(query)) return 0.7;
  if (country.key.toLowerCase().startsWith(query)) return 0.6;
  if (country.value.toLowerCase().includes(query)) return 0.5;
  if (country.key.toLowerCase().includes(query)) return 0.4;
  return 0;
};
