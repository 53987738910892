import {
  GetThemeSuggestionParams,
  fetchThemeSuggestions,
} from './fetchThemeSuggestions';
import { getThemeSuggestionFallback } from './getThemeSuggestionFallback';
import { MIN_SUGGESTION_CACHE_SIZE } from '../../../config/suggestions';
import { getStoredSuggestions } from '@/lib/utils/getStoredSuggestions';
import { addStoredSuggestions } from '@/lib/utils/suggestions/addStoredSuggestions';
import { removeStoredSuggestion } from '@/lib/utils/suggestions/removeStoredSuggestion';

const STORAGE_KEY_PREFIX = 'br_order_theme_suggestions';
const getStorageKey = (
  params: GetThemeSuggestionParams,
  type: 'used' | 'available',
) =>
  `${STORAGE_KEY_PREFIX}:${type}:name=${params.hero_name.replace(/[^a-z0-9]/gi, '_')},dob=${params.hero_dob},gender=${params.hero_gender},occasion=${params.book_occasion.replace(/[^a-z0-9]/gi, '_')}`;

const cacheMoreSuggestions = async (
  order_reference_id: string,
  params: GetThemeSuggestionParams,
  previousSuggestions: string[],
) =>
  addStoredSuggestions(
    getStorageKey(params, 'available'),
    await fetchThemeSuggestions(
      order_reference_id,
      params,
      previousSuggestions,
    ),
  );

const getAvailableSuggestions = (params: GetThemeSuggestionParams) =>
  getStoredSuggestions(getStorageKey(params, 'available'));

const getUsedSuggestions = (params: GetThemeSuggestionParams) =>
  getStoredSuggestions(getStorageKey(params, 'used'));

function markSuggestionUsed(
  params: GetThemeSuggestionParams,
  suggestion: string,
) {
  addStoredSuggestions(getStorageKey(params, 'used'), [suggestion]);
  removeStoredSuggestion(getStorageKey(params, 'available'), suggestion);
}

export function getThemeSuggestion(
  order_reference_id: string,
  params: GetThemeSuggestionParams,
) {
  const availableSuggestions = getAvailableSuggestions(params);

  const [suggestion, ...remainingSuggestions] =
    availableSuggestions.length > 0
      ? availableSuggestions
      : [getThemeSuggestionFallback()];

  if (remainingSuggestions.length <= MIN_SUGGESTION_CACHE_SIZE) {
    cacheMoreSuggestions(
      order_reference_id,
      params,
      getUsedSuggestions(params).concat(availableSuggestions),
    );
  }
  markSuggestionUsed(params, suggestion);

  return suggestion;
}

export function preloadThemeSuggestions(
  order_reference_id: string,
  params: GetThemeSuggestionParams,
) {
  const availableSuggestions = getAvailableSuggestions(params);

  if (availableSuggestions.length < MIN_SUGGESTION_CACHE_SIZE) {
    cacheMoreSuggestions(
      order_reference_id,
      params,
      getUsedSuggestions(params).concat(availableSuggestions),
    );
  }
}
