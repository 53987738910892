import { cn } from '@/lib/utils';
import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import {
  getDayOptions,
  getMonthOptions,
  getYearOptions,
} from '@/lib/utils/date';
import { DropdownMenuProps } from '@radix-ui/react-dropdown-menu';

type DateInputProps = {
  value?: string;
  onChange: (value: string) => void;
  isError?: boolean;
};

export const DateInput = ({ value, onChange, isError }: DateInputProps) => {
  const [y, m, d] = (value || '')
    .split('-')
    .map((v) => (v ? parseInt(v, 10) : undefined));
  const [day, setDay] = useState<number | null>(d || null);
  const [month, setMonth] = useState<number | null>(m || null);
  const [year, setYear] = useState<number | null>(y || null);
  const [dayOpen, setDayOpen] = useState(false);
  const [monthOpen, setMonthOpen] = useState(false);
  const [yearOpen, setYearOpen] = useState(false);

  function getValue(y = year, m = month, d = day) {
    return y && m && d ? `${y}-${m}-${d}` : '';
  }

  return (
    <div className="flex flex-row items-center text-2xl w-full gap-2">
      <NumberSelect
        label="Day"
        options={getDayOptions(month ?? undefined, year ?? undefined)}
        selectedOption={day}
        buttonText={(day) => (day ? day.toString() : 'Day')}
        onSelect={(day) => {
          setDay(day);
          onChange(getValue(undefined, undefined, day));
        }}
        isOpen={dayOpen}
        onOpenChange={setDayOpen}
        isError={!!isError}
      />
      <NumberSelect
        label="Month"
        buttonText={(month) =>
          month
            ? new Date(0, month, 0).toLocaleDateString('en-US', {
                month: 'short',
              })
            : 'Month'
        }
        options={getMonthOptions()}
        selectedOption={month}
        onSelect={(month) => {
          setMonth(month);
          onChange(getValue(undefined, month));
        }}
        isOpen={monthOpen}
        onOpenChange={setMonthOpen}
        isError={!!isError}
      />
      <NumberSelect
        label="Year"
        buttonText={(year) => (year ? year.toString() : 'Year')}
        options={getYearOptions()}
        selectedOption={year}
        onSelect={(year) => {
          setYear(year);
          onChange(getValue(year));
        }}
        isOpen={yearOpen}
        onOpenChange={setYearOpen}
        isError={!!isError}
      />
    </div>
  );
};

const NumberSelect = ({
  label,
  options,
  selectedOption,
  buttonText,
  onSelect,
  isOpen,
  onOpenChange,
  isError,
}: {
  label: string;
  options: { label: string; value: number }[];
  selectedOption: number | null;
  buttonText: (selectedOption: number | null) => string;
  onSelect: (option: number) => void;
  isOpen: boolean;
  isError: boolean;
} & Pick<DropdownMenuProps, 'onOpenChange'>) => (
  <DropdownMenu onOpenChange={onOpenChange}>
    <DropdownMenuTrigger asChild>
      <Button
        variant="outline"
        className={cn(
          'flex-1 flex flex-row items-center justify-between text-2xl py-6 max-sm:px-2',
          isError ? 'border-destructive' : '',
        )}
      >
        {buttonText(selectedOption)}
        <ChevronDown
          className={cn(
            'h-6 w-6 shrink-0 opacity-50 transition-transform',
            isOpen && 'rotate-180',
          )}
        />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="start" className="max-h-64 overflow-auto">
      <DropdownMenuLabel>{label}</DropdownMenuLabel>
      <DropdownMenuSeparator />
      {options.map((option) => (
        <DropdownMenuItem
          key={option.value}
          onClick={() => onSelect(option.value)}
          className={cn(
            'cursor-pointer hover:bg-background',
            selectedOption === option.value
              ? 'bg-accent text-accent-foreground'
              : '',
          )}
        >
          {option.label}
        </DropdownMenuItem>
      ))}
    </DropdownMenuContent>
  </DropdownMenu>
);
