export const getDayOptions = (month = 1, year = 2016) => {
  let days = 0;
  if (month === 2) {
    if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) days = 29;
    else days = 28;
  } else if ([4, 6, 9, 11].includes(month)) {
    days = 30;
  } else days = 31;

  return Array.from({ length: days }, (_, i) => i + 1).map((d) => ({
    label: d.toString(),
    value: d,
  }));
};

export const getMonthOptions = () => {
  return Array.from({ length: 12 }, (_, i) => i + 1).map((m) => ({
    label: new Date(0, m, 0).toLocaleDateString('en-US', { month: 'long' }),
    value: m,
  }));
};

export const getYearOptions = () => {
  return Array.from(
    { length: 100 },
    (_, i) => new Date().getFullYear() - i,
  ).map((y) => ({
    label: y.toString(),
    value: y,
  }));
};
