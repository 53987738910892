import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createLocalStorageMiddleware } from './localStorageMiddleware';
import { z } from 'zod';
import { v4 as uuidv4 } from 'uuid';
import { childDetailsOptionalSchema } from '../schemas/childDetailsSchema';
import { occasionOptionalSchema } from '../schemas/occasionSchema';
import { themeSchema } from '../schemas/themeSchema';
import { dedicationMessageOptionalSchema } from '../schemas/dedicationMessageSchema';
import { personalisationNoteSchema } from '../schemas/personalisationNoteSchema';
import { shippingOptionalSchema } from '../schemas/shippingSchema';
import { similarityOptionalSchema } from '../schemas/similaritySchema';
import { cartOptionalSchema } from '../schemas/cartSchema';
import {
  UploadedImage,
  uploadedImageSchema,
  uploadedImagesSchema,
} from '../schemas/uploadedImageSchema';

const orderFormDataOptionalSchema = childDetailsOptionalSchema
  .merge(occasionOptionalSchema)
  .merge(themeSchema)
  .merge(personalisationNoteSchema)
  .merge(dedicationMessageOptionalSchema)
  .merge(shippingOptionalSchema)
  .merge(similarityOptionalSchema)
  .merge(cartOptionalSchema);

export type OrderFormDataOptional = z.infer<typeof orderFormDataOptionalSchema>;

interface GlobalStore {
  deviceId: string;
  setDeviceId: (deviceId: string) => void;
  firstImage: UploadedImage | null;
  setFirstImage: (image: UploadedImage) => void;
  additionalImages: UploadedImage[];
  order_reference_id: string;
  addAdditionalImages: (images: UploadedImage[]) => void;
  uploadingImages: string[];
  setUploadingImages: (urls: string[]) => void;
  removeFirstImage: () => void;
  removeAdditionalImage: (image: UploadedImage) => void;
  formData: OrderFormDataOptional;
  getFormData: <T>(schema: z.Schema<T>) => T | undefined;
  saveFormData: (data: OrderFormDataOptional) => void;
  clearFormData: () => void;
  lastUpdate: number;
  resetStore: () => void;
}

const initialOrderFormState = {
  deviceId: '',
  firstImage: null,
  additionalImages: [],
  uploadingImages: [],
  order_reference_id: uuidv4(),
  formData: {},
  lastUpdate: Date.now(),
};

const orderFormStateSchema = z.object({
  deviceId: z.string(),
  order_reference_id: z.string(),
  firstImage: uploadedImageSchema.nullable(),
  additionalImages: uploadedImagesSchema,
  formData: orderFormDataOptionalSchema,
  lastUpdate: z.number(),
});

const loadInitialState = () => {
  try {
    return {
      ...initialOrderFormState,
      ...orderFormStateSchema.parse(
        JSON.parse(localStorage.getItem('br_order_form') ?? ''),
      ),
    };
  } catch {
    return initialOrderFormState;
  }
};

export const useGlobalStore = create<GlobalStore>()(
  devtools(
    createLocalStorageMiddleware('br_order_form')((set, get) => ({
      ...loadInitialState(),
      setDeviceId: (deviceId) => set(() => ({ deviceId })),
      setFirstImage: (firstImage) => set(() => ({ firstImage })),
      addAdditionalImages: (additionalImages) =>
        set((state) => ({
          additionalImages: state.additionalImages.concat(additionalImages),
        })),
      setUploadingImages: (uploadingImages) => set(() => ({ uploadingImages })),
      removeFirstImage: () => set(() => ({ firstImage: null })),
      removeAdditionalImage: (image) =>
        set((state) => ({
          additionalImages: state.additionalImages.filter(
            (img) => img.s3_path !== image.s3_path,
          ),
        })),
      saveFormData: (update) =>
        set((state) => ({
          formData: { ...state.formData, ...update },
          lastUpdate: Date.now(),
        })),
      getFormData: (schema) => schema.safeParse(get().formData).data,
      clearFormData: () =>
        set(() => ({ formData: {}, lastUpdate: Date.now() })),
      resetStore: () => set(() => ({ ...initialOrderFormState })),
    })),
  ),
);

export function startNewFlow() {
  localStorage.removeItem('br_order_form');
}
