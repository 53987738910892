import { ReactNode } from 'react';
import { HandleDeviceId } from '../HandleDeviceId';

export function Layout({ children }: { children: ReactNode }) {
  return (
    <div className="h-full min-h-dvh flex flex-col max-w-screen-sm mx-auto">
      {children}
      <HandleDeviceId />
    </div>
  );
}
