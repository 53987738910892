import { z } from 'zod';

export const dedicationMessageSchema = z.object({
  book_dedication_message: z.string().min(1),
});
export type DedicationMessage = z.infer<typeof dedicationMessageSchema>;

export const dedicationMessageOptionalSchema = z.object({
  book_dedication_message: z.string().optional(),
});
export type DedicationMessageOptional = z.infer<
  typeof dedicationMessageOptionalSchema
>;
