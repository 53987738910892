import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import '@/styles/global.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ErrorPage } from './pages/errorPage.tsx';
import { ChildDetailsPage } from './pages/child-details.tsx';
import { OccasionPage } from './pages/occasion.tsx';
import { ThemePage } from './pages/theme.tsx';
import { PersonalisationNotePage } from './pages/note.tsx';
import { DedicationMessagePage } from './pages/message.tsx';
import { PhotoPage } from './pages/photo.tsx';
import { MorePhotosPage } from './pages/more-photos.tsx';
import { ShippingPage } from './pages/shipping.tsx';
import { CartPage } from './pages/cart.tsx';
import { SimilarityPage } from './pages/similarity.tsx';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <ChildDetailsPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: ':lang',
        element: <ChildDetailsPage />,
      },
      {
        path: 'child-details',
        element: <ChildDetailsPage />,
      },
      {
        path: 'occasion',
        element: <OccasionPage />,
      },
      {
        path: 'theme',
        element: <ThemePage />,
      },
      {
        path: 'note',
        element: <PersonalisationNotePage />,
      },
      {
        path: 'message',
        element: <DedicationMessagePage />,
      },
      {
        path: 'photo',
        element: <PhotoPage />,
      },
      {
        path: 'more-photos',
        element: <MorePhotosPage />,
      },
      {
        path: 'shipping',
        element: <ShippingPage />,
      },
      {
        path: 'similarity',
        element: <SimilarityPage />,
      },
      {
        path: 'cart',
        element: <CartPage />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
