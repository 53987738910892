import { z } from 'zod';

export const occasionSchema = z.object({
  book_occasion: z.string().min(1),
});
export type Occasion = z.infer<typeof occasionSchema>;

export const occasionOptionalSchema = z.object({
  book_occasion: z.string().optional(),
});
export type OccasionOptional = z.infer<typeof occasionOptionalSchema>;
