import { rotateSuggestions } from '@/lib/utils/suggestions/rotateSuggestions';

const suggestions = [
  'The main character loves solving puzzles and has a pet dragon.',
  'Include a magical forest where they meet their best friend, Lucy.',
  'Story should include grandparents who live in a cozy cottage.',

  'The main character is brave and always curious. They have a brother named Jonathan and a talking cat named Whiskers.',
  'The story should feature a hidden treasure in the garden, discovered with the help of their best friend, Tim.',
  'Add a scene where the character helps their younger sister, Emma, overcome her fear of the dark.',

  'The main character loves the ocean and dreams of being a marine biologist. Include a scene where they discover a mysterious underwater city with their friend, Max.',
  'The main character lives in a treehouse built by their dad and often has adventures in the nearby forest with their pet parrot, Polly.',
  "They have a secret club with their best friend, Sam, where they solve mysteries. Make sure there's a riddle they must crack to find the hidden treasure.",

  'The main character is a young inventor who loves creating gadgets. Their latest invention is a time machine, which they use to travel to different historical periods with their cousin, Alex. They also enjoy playing chess with their grandpa.',
  'The main character is kind-hearted and always helps others. They have a magical ability to talk to animals, especially their dog, Rex. They love visiting their grandma, who always has a new story to tell about her adventures as a young girl.',
  'The main character is passionate about space and dreams of becoming an astronaut. They often stargaze with their mom, who tells them stories about constellations. Include a chapter where they visit a space station and meet a friendly alien.',
];

const generator = rotateSuggestions(
  [...suggestions].sort(() => Math.random() - 0.5),
);

export const getPersonalisationNoteSuggestionFallback = () =>
  generator.next().value!;
