import { StateCreator } from 'zustand';

export const createLocalStorageMiddleware =
  (storageKey: string) =>
  <T extends object>(config: StateCreator<T>): StateCreator<T> =>
  (set, get, api) =>
    config(
      (args) => {
        set(args);
        localStorage.setItem(storageKey, JSON.stringify(get()));
      },
      get,
      api,
    );
