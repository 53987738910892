import { useMemo, useState } from 'react';
import { Label } from './ui/label';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';
import { Button } from './ui/button';
import { Check, ChevronsUpDown } from 'lucide-react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './ui/command';
import { countries } from '@/lib/utils/orderForm/countries';
import { cn } from '@/lib/utils';

type CountrySelectProps = {
  label: string;
  value?: string;
  onChange: (value: string) => void;
  isError?: boolean;
};

export function CountrySelect({
  label,
  value,
  onChange,
  isError,
}: CountrySelectProps) {
  const selectedCountry = useMemo(
    () => countries.find((v) => v.value === value),
    [value],
  );
  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-col py-8 gap-3">
      <Label htmlFor="br_order_delivery_country_toggle" className="text-2xl">
        {label}
      </Label>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={cn(
              'w-[200px] justify-between',
              isError ? '!border-destructive' : '',
            )}
            id="br_order_delivery_country_toggle"
          >
            {selectedCountry
              ? `${selectedCountry.flag} ${selectedCountry.value}`
              : 'Select Your Country'}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandInput placeholder="Search country..." />
            <CommandEmpty>No country found.</CommandEmpty>
            <CommandList>
              <CommandGroup>
                {countries.map((country) => (
                  <CommandItem
                    key={country.value}
                    value={country.value}
                    onSelect={(val) => {
                      const country = countries.find(
                        (c) => c.value.toLowerCase() === val.toLowerCase(),
                      );
                      onChange(country?.value || '');
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        value === country.value ? 'opacity-100' : 'opacity-0',
                      )}
                    />
                    {country.flag} {country.value}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
