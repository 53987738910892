import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { useGlobalStore } from '../store/global';
import { Gender } from '../schemas/genderSchema';
import { childDetailsSchema } from '../schemas/childDetailsSchema';
import { occasionSchema } from '../schemas/occasionSchema';
import { themeSchema } from '../schemas/themeSchema';
import { personalisationNoteSchema } from '../schemas/personalisationNoteSchema';
import { dedicationMessageSchema } from '../schemas/dedicationMessageSchema';

type GetStoriesParams = {
  hero_name: string;
  hero_gender: Gender;
  hero_dob: string;
  hero_location: string;
  personalisation_note: string;
  dedication_message: string;
  language: 'en';
  theme: string;
  occasion: string;
};

const getStoriesSchema = childDetailsSchema
  .merge(occasionSchema)
  .merge(themeSchema)
  .merge(personalisationNoteSchema)
  .merge(dedicationMessageSchema);

const storiesSchema = z.object({
  story_1: z.string(),
  story_2: z.string(),
  story_3: z.string(),
  story_4: z.string(),
  story_5: z.string(),
  story_6: z.string(),
  title_1: z.string(),
  title_2: z.string(),
  title_3: z.string(),
  title_4: z.string(),
  title_5: z.string(),
  title_6: z.string(),
});

export const useStories = () => {
  const { order_reference_id, getFormData } = useGlobalStore();
  const data = getFormData(getStoriesSchema);
  const params: GetStoriesParams | undefined = data
    ? {
        hero_name: data.hero_name,
        hero_gender: data.hero_gender,
        hero_dob: data.hero_dob,
        hero_location: data.hero_location,
        personalisation_note: data.book_personalisation_note ?? '',
        dedication_message: data.book_dedication_message,
        language: 'en',
        theme: data.book_theme ?? '',
        occasion: data.book_occasion,
      }
    : undefined;
  const { data: started } = useStoryJob(order_reference_id, params);

  return useQuery({
    queryKey: ['stories', order_reference_id, params],
    queryFn: () => getStories(order_reference_id, params!),
    enabled: params && started,
  });
};

const useStoryJob = (order_reference_id: string, params?: GetStoriesParams) =>
  useQuery({
    queryKey: ['storyJob', order_reference_id, params],
    queryFn: () => startStoryGeneration(order_reference_id, params!),
    enabled: !!params,
  });

async function startStoryGeneration(
  order_reference_id: string,
  params: GetStoriesParams,
) {
  const url = new URL('/preview/order-assistant', import.meta.env.VITE_API_URL);
  url.searchParams.append('task', 'write_stories');
  url.searchParams.append('order_reference_id', order_reference_id);

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  if (!response.ok) {
    throw new Error('Failed to start story generation');
  }

  return (
    z.object({ status: z.string() }).parse(await response.json()).status ===
    'success'
  );
}

async function getStories(
  order_reference_id: string,
  params: GetStoriesParams,
) {
  const url = new URL(
    '/preview/order-assistant/get-results',
    import.meta.env.VITE_API_URL,
  );
  url.searchParams.append('task', 'write_stories');
  url.searchParams.append('order_reference_id', order_reference_id);

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  try {
    if (!response.ok) {
      throw new Error('Failed to fetch preview stories');
    }

    return storiesSchema.parse(await response.json());
  } catch {
    return getStories(order_reference_id, params);
  }
}
