import bannerImage from '@/assets/banners/5.avif';
import { Textarea } from '@/components/ui/textarea';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormHeader } from '@/components/FormHeader';
import { Banner } from '@/components/Banner';
import { occasionSchema } from '@/lib/schemas/occasionSchema';
import { ContinueWhereYouLeftOff } from '@/components/orderForm/continueWhereYouLeft';
import { childDetailsSchema } from '@/lib/schemas/childDetailsSchema';
import { useStepNavigation } from '@/lib/utils/useStepNavigation';
import { SubmitButton } from '@/components/SubmitButton';
import { personalisationNoteSchema } from '@/lib/schemas/personalisationNoteSchema';
import { useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Stars } from '@/components/icons/stars';
import {
  getDedicationMessageSuggestion,
  preloadDedicationMessageSuggestions,
} from '@/lib/services/suggestions/dedicationMessage/getDedicationMessageSuggestion';
import { Layout } from '@/components/ui/Layout';
import { FormBody } from '@/components/FormBody';
import { themeSchema } from '@/lib/schemas/themeSchema';
import {
  DedicationMessage,
  dedicationMessageOptionalSchema,
  dedicationMessageSchema,
} from '@/lib/schemas/dedicationMessageSchema';
import { useGlobalStore } from '@/lib/store/global';
import { z } from 'zod';
import { useDataSubmission } from '@/lib/utils';
import { FormField } from '@/components/FormField';

const CURRENT_STEP = 5;

const dedicationMessagePageSchema = childDetailsSchema
  .merge(occasionSchema)
  .merge(themeSchema)
  .merge(personalisationNoteSchema)
  .merge(dedicationMessageOptionalSchema);

export function DedicationMessagePage() {
  const { getFormData } = useGlobalStore();
  const data = getFormData(dedicationMessagePageSchema);
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);

  useEffect(() => {
    if (!data) {
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <DedicationMessageForm data={data} /> : null;
}

function DedicationMessageForm({
  data,
}: {
  data: z.infer<typeof dedicationMessagePageSchema>;
}) {
  const { saveFormData, order_reference_id } = useGlobalStore();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<DedicationMessage>({
    resolver: zodResolver(dedicationMessageSchema),
    defaultValues: data,
  });
  useEffect(() => watch(saveFormData).unsubscribe, [watch, saveFormData]);
  const { goToPreviousStep, goToNextStep } = useStepNavigation(CURRENT_STEP);
  const submitData = useDataSubmission(CURRENT_STEP);
  const onSubmit = () => submitData().finally(goToNextStep);
  useEffect(() => {
    preloadDedicationMessageSuggestions(order_reference_id, data);
  }, [order_reference_id, data]);

  return (
    <Layout>
      <FormHeader
        title={`${data.hero_name}'s Bespoke Adventure`}
        currentStep={CURRENT_STEP}
        onBack={goToPreviousStep}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody>
          <div className="flex-1 lg:max-h-fit">
            <div className="h-fit">
              <div className="relative mx-auto">
                <Banner src={bannerImage} />
              </div>
              <FormField
                label={`Write your own message for ${data.hero_name}`}
                description="Share your dedication or best wishes to be printed in the book."
                inputId="br_order_book_dedication_message"
              >
                <Textarea
                  {...register('book_dedication_message')}
                  id="br_order_book_dedication_message"
                  placeholder="Your Message"
                  isError={!!errors.book_dedication_message}
                />
                <div className="flex flex-row items-start justify-start gap-2">
                  <Button
                    variant="outline"
                    type="button"
                    className="h-full uppercase border-foreground flex flex-row items-center justify-center gap-4"
                    onClick={() => {
                      setValue(
                        'book_dedication_message',
                        getDedicationMessageSuggestion(
                          order_reference_id,
                          data,
                        ),
                      );
                    }}
                  >
                    <Stars />
                    Inspire me
                  </Button>
                </div>
              </FormField>
            </div>
          </div>
          <SubmitButton />
        </FormBody>
      </form>
      <ContinueWhereYouLeftOff />
    </Layout>
  );
}
