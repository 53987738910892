import { Skeleton } from '@/components/ui/skeleton';
import { useStories } from '@/lib/hooks/useStories';
import { useStoryImage } from '@/lib/hooks/useStoryImage';

export function Preview({ hero_name }: { hero_name: string }) {
  const { data: previewImage } = useStoryImage();
  const { data: stories } = useStories();

  return (
    <div className="flex flex-col gap-4">
      <h2 className="w-full text-center text-2xl pb-1">
        {hero_name}'s Storybook Preview
      </h2>

      {previewImage && stories ? (
        <>
          <p>
            Preview one of six bespoke stories for {hero_name}. The final book
            will feature enhanced illustrations and polished stories.
          </p>

          <div className="flex flex-col">
            <div style={{ aspectRatio: '2 / 1' }}>
              <img
                src={previewImage}
                alt="book preview"
                className="w-full rounded-t-lg"
              />
            </div>
            <div className="bg-tertiary text-primary-foreground p-2 rounded-lg font-semibold flex justify-center items-center">
              <p className="text-center">
                Initial preview, the final illustrations will be artistically
                refined by our artists. 🧑‍🎨
              </p>
            </div>
          </div>

          {([2, 3, 4, 5, 6] as const).map((i) => (
            <div
              key={i}
              className="flex flex-col gap-2 border p-2 rounded-lg bg-card"
            >
              <h3 className="font-semibold">
                {i}. {stories[`title_${i}`]}
              </h3>
              <p className="text-sm">{stories[`story_${i}`]}</p>
            </div>
          ))}
        </>
      ) : (
        <>
          <Skeleton className="w-full h-10" />
          <Skeleton className="w-full" style={{ aspectRatio: '2 / 1' }} />
          <Skeleton className="w-full h-16" />
          <Skeleton className="w-full h-16" />
          <Skeleton className="w-full h-16" />
          <Skeleton className="w-full h-16" />
          <Skeleton className="w-full h-16" />
        </>
      )}
    </div>
  );
}
