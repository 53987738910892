import { useGlobalStore } from '@/lib/store/global';
import mixpanel from 'mixpanel-browser';
import { v4 as uuidv4 } from 'uuid';

export const useMixpanel = () => {
  if (import.meta.env.VITE_MIXPANEL_TOKEN === undefined) {
    throw new Error('VITE_MIXPANEL_TOKEN is not defined');
  }

  mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN);

  const { setDeviceId } = useGlobalStore();

  return function handleDeviceId() {
    let deviceId = getCookie('DEVICE_ID');
    if (!deviceId) {
      deviceId = generateDeviceId();
      setDeviceIdCookie(deviceId);
    }
    setDeviceId(deviceId);
  };
};

function generateDeviceId() {
  if (
    typeof mixpanel !== 'undefined' &&
    mixpanel.get_distinct_id &&
    mixpanel.get_distinct_id()
  ) {
    return String(mixpanel.get_distinct_id());
  }

  return '$device:' + uuidv4();
}

// Function to set the device ID cookie
function setDeviceIdCookie(deviceId: string) {
  const daysToExpire = 365; // Set cookie to expire in one year
  const expiryDate = new Date();
  expiryDate.setTime(expiryDate.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
  document.cookie =
    'DEVICE_ID=' +
    deviceId +
    ';expires=' +
    expiryDate.toUTCString() +
    ';path=/;secure';
}

// Function to get a cookie by name
function getCookie(name: string) {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');

  if (parts.length == 2 && parts.every((v) => v))
    return parts.pop()!.split(';').shift();
  else return null;
}
