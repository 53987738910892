import { FormHeader } from '@/components/FormHeader';
import { ContinueWhereYouLeftOff } from '@/components/orderForm/continueWhereYouLeft';
import {
  ChildDetails,
  childDetailsSchema,
} from '@/lib/schemas/childDetailsSchema';
import { useStepNavigation } from '@/lib/utils/useStepNavigation';
import { SubmitButton } from '@/components/SubmitButton';
import { useEffect, useState } from 'react';
import { Layout } from '@/components/ui/Layout';
import { FormBody } from '@/components/FormBody';
import { ImageStep } from '@/components/orderForm/imageStep';
import { useGlobalStore } from '@/lib/store/global';
import { useDataSubmission } from '@/lib/utils';
import { useStories } from '@/lib/hooks/useStories';

const CURRENT_STEP = 7;

export function MorePhotosPage() {
  const { getFormData } = useGlobalStore();
  const data = getFormData(childDetailsSchema);
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);

  useEffect(() => {
    if (!data) {
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <MorePhotosForm data={data} /> : null;
}

function MorePhotosForm({ data }: { data: ChildDetails }) {
  useStories(); // preload stories
  const { goToPreviousStep, goToNextStep } = useStepNavigation(CURRENT_STEP);
  const { additionalImages, uploadingImages } = useGlobalStore();
  const [submitted, setSubmitted] = useState(false);
  const submitData = useDataSubmission(CURRENT_STEP);
  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (additionalImages.length < 4) {
      return;
    }
    try {
      await submitData();
    } finally {
      goToNextStep();
    }
  };

  return (
    <Layout>
      <FormHeader
        title={`${data.hero_name}'s Bespoke Adventure`}
        currentStep={CURRENT_STEP}
        onBack={uploadingImages.length > 0 ? undefined : goToPreviousStep}
      />
      <form onSubmit={onSubmit}>
        <FormBody>
          <div className="flex-1 lg:max-h-fit">
            <ImageStep
              label="More Photos for Perfection"
              description="Upload four more clear photos to bring the hero in all the stories to life."
              imageCount={4}
              images={additionalImages.concat(
                uploadingImages.length > 0
                  ? uploadingImages.map((i) => ({
                      presigned_url: i,
                      original_file_name: i,
                      s3_path: i,
                    }))
                  : [],
              )}
              isError={submitted && additionalImages.length < 4}
            />
          </div>
          <SubmitButton disabled={uploadingImages.length > 0} />
        </FormBody>
      </form>
      <ContinueWhereYouLeftOff />
    </Layout>
  );
}
