import { ChildDetails } from '@/lib/schemas/childDetailsSchema';
import { fetchOccasionSuggestions } from './fetchOccasionSuggestions';

const STORAGE_KEY_PREFIX = 'br_order_occasion_suggestions';

const fallbackSuggestions = [
  'I Love Books',
  'Birthday Surprise',
  'To Read More',
  'Gift From Grandparents',
  'First Day At School',
  'Bedtime Ritual',
  'Easter fun',
  'Holiday Gift',
  'Graduation Gift',
];

const suggestionsPromises: Record<string, Promise<string[] | undefined>> = {};

export async function getOccasionSuggestions(
  order_reference_id: string,
  params: ChildDetails,
) {
  const storageKey = `${STORAGE_KEY_PREFIX}:name=${params.hero_name},dob=${params.hero_dob},gender=${params.hero_gender}`;
  const cachedSuggestions = localStorage.getItem(storageKey);

  if (cachedSuggestions) {
    return JSON.parse(cachedSuggestions);
  }

  if (!suggestionsPromises[storageKey]) {
    suggestionsPromises[storageKey] = fetchOccasionSuggestions(
      order_reference_id,
      params,
    )
      .catch(() => [...fallbackSuggestions].sort(() => Math.random() - 0.5))
      .then((suggestions) => {
        localStorage.setItem(storageKey, JSON.stringify(suggestions));
        return suggestions;
      });
  }

  return suggestionsPromises[storageKey];
}
