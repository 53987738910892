import girl1 from '@/assets/examples/girl/1.avif';
import girl2 from '@/assets/examples/girl/2.webp';
import girl3 from '@/assets/examples/girl/3.webp';
import girl4 from '@/assets/examples/girl/4.webp';
import girl5 from '@/assets/examples/girl/5.webp';
import girl6 from '@/assets/examples/girl/6.webp';
import girl7 from '@/assets/examples/girl/7.webp';
import girl8 from '@/assets/examples/girl/8.webp';
import girl9 from '@/assets/examples/girl/9.webp';
import girl10 from '@/assets/examples/girl/10.webp';
import girl11 from '@/assets/examples/girl/11.webp';
import girl12 from '@/assets/examples/girl/12.webp';
import girl13 from '@/assets/examples/girl/13.webp';

import boy1 from '@/assets/examples/boy/1.avif';
import boy2 from '@/assets/examples/boy/2.webp';
import boy3 from '@/assets/examples/boy/3.webp';
import boy4 from '@/assets/examples/boy/4.webp';
import boy5 from '@/assets/examples/boy/5.webp';
import boy6 from '@/assets/examples/boy/6.webp';
import boy7 from '@/assets/examples/boy/7.webp';
import boy8 from '@/assets/examples/boy/8.webp';
import boy9 from '@/assets/examples/boy/9.webp';
import boy10 from '@/assets/examples/boy/10.webp';
import boy11 from '@/assets/examples/boy/11.webp';
import boy12 from '@/assets/examples/boy/12.webp';
import boy13 from '@/assets/examples/boy/13.webp';

import style1 from '@/assets/examples/style/1.avif';
import style2 from '@/assets/examples/style/2.avif';
import style3 from '@/assets/examples/style/3.avif';
import style4 from '@/assets/examples/style/4.avif';
import stylePreview1 from '@/assets/examples/style/preview_1.avif';
import stylePreview2 from '@/assets/examples/style/preview_2.avif';
import stylePreview3 from '@/assets/examples/style/preview_3.avif';
import stylePreview4 from '@/assets/examples/style/preview_4.avif';

export const girlImages = [
  girl1,
  girl2,
  girl3,
  girl4,
  girl5,
  girl6,
  girl7,
  girl8,
  girl9,
  girl10,
  girl11,
  girl12,
  girl13,
];

export const boyImages = [
  boy1,
  boy2,
  boy3,
  boy4,
  boy5,
  boy6,
  boy7,
  boy8,
  boy9,
  boy10,
  boy11,
  boy12,
  boy13,
];

export const styleImages = [
  {
    photo: style1,
    story: (
      <p>
        A 1-year-old girl who loves her neighbours' small white dog, Fluffy, and
        will soon have one of her own. The first story in her book is titled{' '}
        <strong>
          <i>"Space Journey with Fluffy"</i>
        </strong>
        .
      </p>
    ),
    preview: stylePreview1,
  },
  {
    photo: style2,
    story: (
      <p>
        A 7-year-old boy whose favourite sport is basketball. The first story in
        his book is titled{' '}
        <strong>
          <i>"Galactic Hoops Adventure"</i>
        </strong>
        .
      </p>
    ),
    preview: stylePreview2,
  },
  {
    photo: style3,
    story: (
      <p>
        A 4-year-old girl who recently started kayaking and loves it. The first
        story in her book is titled{' '}
        <strong>
          <i>"Brave Ava's Kayak Adventure"</i>
        </strong>
        .
      </p>
    ),
    preview: stylePreview3,
  },
  {
    photo: style4,
    story: (
      <p>
        A 6-year-old boy who dreams of having his own scooter. The first story
        in his book is titled{' '}
        <strong>
          <i>"Riding with the Wind in Cambridge"</i>
        </strong>
        .
      </p>
    ),
    preview: stylePreview4,
  },
];
