export const countries = [
  {
    key: 'gb',
    value: 'United Kingdom',
    flag: '🇬🇧',
    prefix: '+44',
    currency: 'GBP',
    placeholder: '07400 123456',
    symbol: '£',
  },
  {
    key: 'us',
    value: 'United States',
    flag: '🇺🇸',
    prefix: '+1',
    currency: 'USD',
    placeholder: '(201) 555-0123',
    symbol: '$',
  },
  {
    key: 'at',
    value: 'Austria',
    flag: '🇦🇹',
    prefix: '+43',
    currency: 'EUR',
    placeholder: '0664 123456',
    symbol: '€',
  },
  {
    key: 'be',
    value: 'Belgium',
    flag: '🇧🇪',
    prefix: '+32',
    currency: 'EUR',
    placeholder: '0470 12 34 56',
    symbol: '€',
  },
  {
    key: 'bg',
    value: 'Bulgaria',
    flag: '🇧🇬',
    prefix: '+359',
    currency: 'BGN',
    placeholder: '043 012 345',
    symbol: 'лв',
  },
  {
    key: 'hr',
    value: 'Croatia',
    flag: '🇭🇷',
    prefix: '+385',
    currency: 'HRK',
    placeholder: '092 123 4567',
    symbol: 'kn',
  },
  {
    key: 'cz',
    value: 'Czech Republic',
    flag: '🇨🇿',
    prefix: '+420',
    currency: 'CZK',
    placeholder: '601 123 456',
    symbol: 'Kč',
  },
  {
    key: 'dk',
    value: 'Denmark',
    flag: '🇩🇰',
    prefix: '+45',
    currency: 'DKK',
    placeholder: '34 41 23 45',
    symbol: 'kr',
  },
  {
    key: 'ee',
    value: 'Estonia',
    flag: '🇪🇪',
    prefix: '+372',
    currency: 'EUR',
    placeholder: '5123 4567',
    symbol: '€',
  },
  {
    key: 'fi',
    value: 'Finland',
    flag: '🇫🇮',
    prefix: '+358',
    currency: 'EUR',
    placeholder: '041 2345678',
    symbol: '€',
  },
  {
    key: 'fr',
    value: 'France',
    flag: '🇫🇷',
    prefix: '+33',
    currency: 'EUR',
    placeholder: '06 12 34 56 78',
    symbol: '€',
  },
  {
    key: 'de',
    value: 'Germany',
    flag: '🇩🇪',
    prefix: '+49',
    currency: 'EUR',
    placeholder: '01512 3456789',
    symbol: '€',
  },
  {
    key: 'gr',
    value: 'Greece',
    flag: '🇬🇷',
    prefix: '+30',
    currency: 'EUR',
    placeholder: '691 234 5678',
    symbol: '€',
  },
  {
    key: 'hu',
    value: 'Hungary',
    flag: '🇭🇺',
    prefix: '+36',
    currency: 'HUF',
    placeholder: '06 20 123 4567',
    symbol: 'Ft',
  },
  {
    key: 'is',
    value: 'Iceland',
    flag: '🇮🇸',
    prefix: '+354',
    currency: 'ISK',
    placeholder: '611 1234',
    symbol: 'kr',
  },
  {
    key: 'in',
    value: 'India',
    flag: '🇮🇳',
    prefix: '+91',
    currency: 'INR',
    placeholder: '081234 56789',
    symbol: '₹',
  },
  {
    key: 'ie',
    value: 'Ireland',
    flag: '🇮🇪',
    prefix: '+353',
    currency: 'EUR',
    placeholder: '085 012 3456',
    symbol: '€',
  },
  {
    key: 'it',
    value: 'Italy',
    flag: '🇮🇹',
    prefix: '+39',
    currency: 'EUR',
    placeholder: '312 345 6789',
    symbol: '€',
  },
  {
    key: 'lv',
    value: 'Latvia',
    flag: '🇱🇻',
    prefix: '+371',
    currency: 'EUR',
    placeholder: '21 234 567',
    symbol: '€',
  },
  {
    key: 'li',
    value: 'Liechtenstein',
    flag: '🇱🇮',
    prefix: '+423',
    currency: 'CHF',
    placeholder: '660 234 567',
    symbol: 'CHF',
  },
  {
    key: 'lt',
    value: 'Lithuania',
    flag: '🇱🇹',
    prefix: '+370',
    currency: 'EUR',
    placeholder: '(0-612) 34567',
    symbol: '€',
  },
  {
    key: 'lu',
    value: 'Luxembourg',
    flag: '🇱🇺',
    prefix: '+352',
    currency: 'EUR',
    placeholder: '628 123 456',
    symbol: '€',
  },
  {
    key: 'mt',
    value: 'Malta',
    flag: '🇲🇹',
    prefix: '+356',
    currency: 'EUR',
    placeholder: '9696 1234',
    symbol: '€',
  },
  {
    key: 'nl',
    value: 'Netherlands',
    flag: '🇳🇱',
    prefix: '+31',
    currency: 'EUR',
    placeholder: '06 12345678',
    symbol: '€',
  },
  {
    key: 'no',
    value: 'Norway',
    flag: '🇳🇴',
    prefix: '+47',
    currency: 'NOK',
    placeholder: '40 61 23 45',
    symbol: 'kr',
  },
  {
    key: 'pl',
    value: 'Poland',
    flag: '🇵🇱',
    prefix: '+48',
    currency: 'PLN',
    placeholder: '512 345 678',
    symbol: 'zł',
  },
  {
    key: 'pt',
    value: 'Portugal',
    flag: '🇵🇹',
    prefix: '+351',
    currency: 'EUR',
    placeholder: '912 345 678',
    symbol: '€',
  },
  {
    key: 'cy',
    value: 'Republic of Cyprus',
    flag: '🇨🇾',
    prefix: '+357',
    currency: 'EUR',
    placeholder: '96 123456',
    symbol: '€',
  },
  {
    key: 'ro',
    value: 'Romania',
    flag: '🇷🇴',
    prefix: '+40',
    currency: 'RON',
    placeholder: '0712 034 567',
    symbol: 'lei',
  },
  {
    key: 'sk',
    value: 'Slovakia',
    flag: '🇸🇰',
    prefix: '+421',
    currency: 'EUR',
    placeholder: '0912 123 456',
    symbol: '€',
  },
  {
    key: 'si',
    value: 'Slovenia',
    flag: '🇸🇮',
    prefix: '+386',
    currency: 'EUR',
    placeholder: '031 234 567',
    symbol: '€',
  },
  {
    key: 'es',
    value: 'Spain',
    flag: '🇪🇸',
    prefix: '+34',
    currency: 'EUR',
    placeholder: '612 34 56 78',
    symbol: '€',
  },
  {
    key: 'se',
    value: 'Sweden',
    flag: '🇸🇪',
    prefix: '+46',
    currency: 'SEK',
    placeholder: '070-123 45 67',
    symbol: 'kr',
  },
  {
    key: 'ch',
    value: 'Switzerland',
    flag: '🇨🇭',
    prefix: '+41',
    currency: 'CHF',
    placeholder: '078 123 45 67',
    symbol: 'CHF',
  },
] as const;
