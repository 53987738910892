import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { useGlobalStore } from '../store/global';
import { Gender } from '../schemas/genderSchema';
import { childDetailsSchema } from '../schemas/childDetailsSchema';
import { useStories } from './useStories';

type GetHeroImagesParams = {
  story_title: string;
  story_text: string;
  hero_gender: Gender;
  hero_image_urls: string[];
};

const heroImagesSchema = z.object({
  mj_job_id: z.string().min(1),
  hero_preview_image_urls_low_res: z.array(z.string().min(1)).length(4),
});

export const useHeroImages = () => {
  const { order_reference_id, getFormData, firstImage, additionalImages } =
    useGlobalStore();
  const { data: stories } = useStories();
  const data = getFormData(childDetailsSchema);
  const params: GetHeroImagesParams | undefined =
    data && stories
      ? {
          story_title: stories.title_1,
          story_text: stories.story_1,
          hero_gender: data.hero_gender,
          hero_image_urls: [
            ...(firstImage ? [firstImage] : []),
            ...additionalImages,
          ].map((img) => img.presigned_url),
        }
      : undefined;
  const { data: started } = useHeroImagesJob(order_reference_id, params);

  return useQuery({
    queryKey: ['heroImages', order_reference_id, params],
    queryFn: () => getHeroImages(order_reference_id, params!),
    enabled: params && started,
  });
};

const useHeroImagesJob = (
  order_reference_id: string,
  params?: GetHeroImagesParams,
) =>
  useQuery({
    queryKey: ['heroImagesJob', order_reference_id, params],
    queryFn: () => startHeroImagesGeneration(order_reference_id, params!),
    enabled: !!params,
  });

async function startHeroImagesGeneration(
  order_reference_id: string,
  params: GetHeroImagesParams,
) {
  const url = new URL('/preview/order-assistant', import.meta.env.VITE_API_URL);
  url.searchParams.append('task', 'hero_images');
  url.searchParams.append('order_reference_id', order_reference_id);

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  if (!response.ok) {
    throw new Error('Failed to start hero images generation');
  }

  return (
    z.object({ status: z.string() }).parse(await response.json()).status ===
    'success'
  );
}

async function getHeroImages(
  order_reference_id: string,
  params: GetHeroImagesParams,
) {
  const url = new URL(
    '/preview/order-assistant/get-results',
    import.meta.env.VITE_API_URL,
  );
  url.searchParams.append('task', 'hero_images');
  url.searchParams.append('order_reference_id', order_reference_id);

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  try {
    if (!response.ok) {
      throw new Error('Failed to fetch hero images');
    }

    return heroImagesSchema.parse(await response.json());
  } catch {
    return getHeroImages(order_reference_id, params);
  }
}
