import { Loader } from 'google-maps';
import { Textarea, TextareaProps } from './ui/textarea';
import { useEffect, useRef, useState } from 'react';
import { countries } from '@/lib/utils/orderForm/countries';
import { Command, CommandGroup, CommandItem, CommandList } from './ui/command';

export type AddressSuggestion = {
  formattedAddress: string;
  streetNumber?: string;
  route?: string;
  postcode?: string;
  locality?: string;
  postalTown?: string;
};

// Delay closing suggestions list when input loses focus
// to give suggestion selection listener a chance to fire
const BLUR_CLOSE_DELAY_TIMEOUT = 150;

export function AddressInput({
  country,
  onAcceptSuggestion,
  ...props
}: TextareaProps & {
  country: string;
  value: string;
  onAcceptSuggestion: (address: AddressSuggestion) => void;
}) {
  const [open, setOpen] = useState(false);
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService>();
  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService>();
  const attrContainerRef = useRef<HTMLDivElement>(null);

  async function loadAutocomplete() {
    if (attrContainerRef.current) {
      const loader = new Loader(import.meta.env.VITE_GOOGLE_MAPS_API_KEY, {
        version: 'weekly',
        libraries: ['places'],
      });
      const google = await loader.load();

      const autocompleteService = new google.maps.places.AutocompleteService();
      setAutocompleteService(autocompleteService);
      const placesService = new google.maps.places.PlacesService(
        attrContainerRef.current,
      );
      setPlacesService(placesService);
    }
  }

  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  useEffect(() => {
    if (!open) {
      setPredictions([]);
      return;
    }
    if (autocompleteService) {
      getPlacePredictions(autocompleteService, country, props.value)
        .then(setPredictions)
        .catch(() => setPredictions([]));
    }
  }, [autocompleteService, country, props.value, open]);

  return (
    <>
      <Textarea
        {...props}
        onFocus={() => {
          if (!autocompleteService) {
            loadAutocomplete().then(() => setOpen(true));
          } else {
            setOpen(true);
          }
        }}
        onBlur={() => {
          setTimeout(() => {
            if (open) {
              setOpen(false);
            }
          }, BLUR_CLOSE_DELAY_TIMEOUT);
        }}
      />
      <Command className={open ? '-mb-8' : ''}>
        <CommandList>
          <CommandGroup
            className={!open || predictions.length === 0 ? 'p-0' : ''}
          >
            {predictions.map((prediction) => (
              <CommandItem
                key={prediction.place_id}
                value={prediction.place_id}
                onSelect={async (placeId) => {
                  const details = await getPlaceDetails(
                    placesService!,
                    placeId,
                  );
                  const components = details.address_components ?? [];
                  const formattedAddress = details.formatted_address ?? '';

                  onAcceptSuggestion(
                    getAddressSuggestion(formattedAddress, components),
                  );
                  setOpen(false);
                }}
              >
                {prediction.description}
              </CommandItem>
            ))}
          </CommandGroup>
          <div ref={attrContainerRef} />
        </CommandList>
      </Command>
    </>
  );
}

async function getPlacePredictions(
  service: google.maps.places.AutocompleteService,
  country: string,
  input: string,
) {
  return new Promise<google.maps.places.AutocompletePrediction[]>(
    (resolve, reject) => {
      service.getPlacePredictions(
        {
          input,
          types: ['address'],
          componentRestrictions: {
            country: countries.find((c) => c.value === country)?.key || '',
          },
        },
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(predictions);
          } else {
            reject(status);
          }
        },
      );
    },
  );
}

async function getPlaceDetails(
  service: google.maps.places.PlacesService,
  placeId: string,
) {
  return new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
    service.getDetails(
      { placeId, fields: ['address_components', 'formatted_address'] },
      (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(place);
        } else {
          reject(status);
        }
      },
    );
  });
}

function getAddressSuggestion(
  formattedAddress: string,
  components: google.maps.GeocoderAddressComponent[],
) {
  return {
    formattedAddress,
    streetNumber: components.find((c) => c.types.includes('street_number'))
      ?.long_name,
    route: components.find((c) => c.types.includes('route'))?.long_name,
    postcode: components.find((c) => c.types.includes('postal_code'))
      ?.long_name,
    locality: components.find((c) => c.types.includes('locality'))?.long_name,
    postalTown: components.find((c) => c.types.includes('postal_town'))
      ?.long_name,
  };
}
