import { useEffect } from 'react';

export function WarnBeforeUnload() {
  function handleBeforeUnload(event: BeforeUnloadEvent) {
    const message =
      'Are you sure you want to leave this page? You will lose any unsaved data.';
    event.returnValue = message;
    return message;
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return null;
}
