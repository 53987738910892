import { rotateSuggestions } from '@/lib/utils/suggestions/rotateSuggestions';

const suggestions = [
  'Curiosity',
  'Harmony',
  'Dinosaurs',
  'Adventure',
  'Clever Fox',
  'Bright Dreams',
  'Magical Forests',
  'Family Bonds',
  'Puzzles and Riddles',
  'Rainbow of Cultures',
  'Time-Traveling Kids',
  'Lost in Woods',
  'Thinking Cap Chronicles',
  'Embracing New Worlds',
  'Little Acts of Kindness',
  'Animal Friends Forever',
  'Enchanted Garden Discoveries',
  'Celebrating All Differences',
  'Imagination Under The Stars',
  'Space Pirates and Hidden Treasure',
];

const generator = rotateSuggestions(
  [...suggestions].sort(() => Math.random() - 0.5),
);

export const getThemeSuggestionFallback = () => generator.next().value!;
